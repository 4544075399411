import { FunctionComponent } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { Box, Text } from '@chakra-ui/react';

export interface DropItem {
  files?: File[];
}
interface TargetBoxProps {
  onDrop: (item: DropItem) => any;
}
export const TargetBox: FunctionComponent<TargetBoxProps> = ({ onDrop }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(item: DropItem) {
      if (onDrop) {
        onDrop(item);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  }));

  const isActive = canDrop && isOver;
  return (
    <Box
      bg="gray.50"
      w="100%"
      p={4}
      border="2px"
      borderColor="gray.400"
      borderStyle="dashed"
      ref={drop}
    >
      <Text>{isActive ? 'Release to upload' : 'Drag and drop file here'}</Text>
    </Box>
  );
};
