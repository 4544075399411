import React, { FunctionComponent } from 'react';
import {
  Stack,
  useColorModeValue,
  Image,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import { CenterLayout } from '../layouts/CenterLayout';

import { Link } from 'react-router-dom';

const Home: FunctionComponent = () => {
  return (
    <CenterLayout>
      <Stack spacing={4}>
        <LinkBox
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow="md"
          p={4}
        >
          <LinkOverlay to="/snowtistics" as={Link}>
            <Image src="/logo.png" alt="Snowtistics" height="36px" />
          </LinkOverlay>
        </LinkBox>
      </Stack>
    </CenterLayout>
  );
};
export default Home;
