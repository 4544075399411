import React, { useCallback, useEffect, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import Cookie from "js-cookie";

const TOKEN_KEY = 'token';
const COOKIE_KEY = 'node-api';

interface AuthContextProvider {
  axios: AxiosInstance;
  loading: boolean;
  token: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = React.createContext<AuthContextProvider>({
  axios: axios.create(),
  loading: true,
  token: null,
  login: () => Promise.reject(new Error('Not implemented')),
  logout: () => {},
});

const AuthProvider: React.FC = (props) => {

  const tokenFromAdmin = Cookie.get(COOKIE_KEY);
  if (tokenFromAdmin) {
    window.localStorage.setItem(TOKEN_KEY, tokenFromAdmin);
    Cookie.remove(COOKIE_KEY);
  }

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(tokenFromAdmin ?? null); 

  const loadToken = useCallback(() => {
    setToken(window.localStorage.getItem(TOKEN_KEY));
    setLoading(false);
  }, []);

  const removeToken = () => {
    window.localStorage.removeItem(TOKEN_KEY);
    setToken(null);
  };

  const saveToken = (token: string) => {
    window.localStorage.setItem(TOKEN_KEY, token);
    setToken(token);
  };

  const login = async (email: string, password: string) => {
    const res = await axiosInstance.post('/auth/login', { email, password });
    if (res.status === 200 && res.data.accessToken) {
      saveToken(res.data.accessToken);
    } else {
      throw new Error('Invalid credentials');
    }
  };

  const logout = () => {
    removeToken();
  };

  useEffect(() => {
    loadToken();
  }, [loadToken]);

  axiosInstance.interceptors.request.use((config) => {
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  });

  return (
    <AuthContext.Provider
      value={{ loading, login, logout, token, axios: axiosInstance }}
      {...props}
    />
  );
};

export const useAuth = () => React.useContext(AuthContext);
export default AuthProvider;
