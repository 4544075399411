import React, { useContext, useState } from 'react';
import ReportContext from '../context/reportContext';
import {
  Button,
  ButtonGroup,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReportLocation } from '../types';
import { ArrowDownIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import SnowIceBoundsModal, {
  SnowIceBoundsModalResult,
} from './SnowIceBoundsModal';
import download from 'downloadjs';
import { useAuth } from '../context/authContext';

interface ReportResponse {
  base64: string;
  name: string;
}

const ReportDownloadList = ({ locations }: { locations: ReportLocation[] }) => {
  const reportContext = useContext(ReportContext);
  if (!reportContext) {
    throw new Error('CstDatasetList must be used within a ReportContext');
  }
  const { axios } = useAuth();
  const { report } = reportContext;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLocation, setSelectedLocation] = useState<ReportLocation>();
  const [downloadAll, setDownloadAll] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<'xlsx' | 'pdf'>(
    'pdf'
  );

  const [isRunningReport, setIsRunningReport] = useState(false);

  const isMultiLocation = !report.type.includes('snowtistics');

  const handleDownloadCstData = (location: ReportLocation) => {
    // TODO: Stub
  };

  const createReport = async (
    locations: ReportLocation[],
    fileType: 'pdf' | 'xlsx',
    snowRanges?: number[],
    iceRanges?: number[],
    snowEventRanges?: number[],
    snowEventLabels?: string[]
  ) => {
    setIsRunningReport(true);

    /**
     * HACK: The API will account for the winter season spanning years by incrementing the year by 1,
     * which skews the report by a year. We'll just subtract 1 from the year to account for this, and
     * look into a better solution in the future.
     */
    let endYear = report.endYear;
    if (report.periodType === 'Winter Season') {
      endYear = endYear - 1;
    }

    const data: any = {
      ...report,
      endYear,
      snowRanges,
      iceRanges,
      fileType,
      locations,
      snowEventRanges,
      snowEventLabels,
    };

    try {
      const {
        data: { base64, name },
      } = await axios
        .post<{ data: ReportResponse }>('/snowtistics/reports', data)
        .then((res) => {
          return res.data;
        });

      const mimeType =
        fileType === 'pdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      const dataUri = `data:${mimeType};base64,${base64}`;
      download(dataUri, name, mimeType);
    } catch (e) {
      console.error(e);
    } finally {
      setIsRunningReport(false);
    }
  };

  const handleRunClimatologyReport = (
    location: ReportLocation | undefined,
    fileType: 'pdf' | 'xlsx',
    downloadAll?: boolean
  ) => {
    let reportLocations: ReportLocation[] = [];
    if (location) {
      setSelectedLocation(location);
      reportLocations.push(location);
    } else {
      setSelectedLocation(undefined);
      // We'll assume this is a multi-location report and add all locations to the list
      reportLocations.push(...locations);
    }
    setSelectedFileType(fileType);
    setDownloadAll(downloadAll || false);
    if (
      report.type === 'snowtistics' ||
      report.type === 'snowtistics-lite' ||
      (report.type === 'custom' &&
        report.customColumns?.includes('eventCountAverages'))
    ) {
      onOpen();
    } else {
      createReport(reportLocations, fileType);
    }
  };

  const handleSnowIceBoundsSubmit = (result: SnowIceBoundsModalResult) => {
    const { snowRanges, iceRanges, snowEventRanges, snowEventLabels } = result;

    let reportLocations: ReportLocation[] = [];
    if (selectedLocation) {
      reportLocations.push(selectedLocation);
    } else {
      // We'll assume this is a multi-location report and add all locations to the list
      reportLocations.push(...locations);
    }

    if (downloadAll) {
      reportLocations.forEach((location) => {
        createReport(
          [location],
          selectedFileType,
          snowRanges,
          iceRanges,
          snowEventRanges,
          snowEventLabels
        );
      });
    } else {
      createReport(
        reportLocations,
        selectedFileType,
        snowRanges,
        iceRanges,
        snowEventRanges,
        snowEventLabels
      );
    }

    onClose();
  };

  const DatasetList = ({ locations }: { locations: ReportLocation[] }) => {
    return (
      <List spacing={4}>
        {locations.map((location, index) => {
          return (
            <ListItem key={`location${index}`}>
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ justifyContent: 'space-between' }}
              >
                <Text>{location.name}</Text>

                <ButtonGroup size={'sm'} spacing="2">
                  {report.type === 'raw' && (
                    <Button
                      leftIcon={<ArrowDownIcon />}
                      onClick={(e) => handleDownloadCstData(location)}
                    >
                      Download CST Data
                    </Button>
                  )}
                  {report.type !== 'raw' && (
                    <>
                      <Button
                        isLoading={
                          isRunningReport &&
                          selectedLocation === location &&
                          selectedFileType === 'xlsx'
                        }
                        loadingText="Running Report&hellip;"
                        leftIcon={<ArrowDownIcon />}
                        onClick={() =>
                          handleRunClimatologyReport(location, 'xlsx')
                        }
                        colorScheme="green"
                      >
                        Download {report.type}.xlsx
                      </Button>

                      <Button
                        isLoading={
                          isRunningReport &&
                          selectedLocation === location &&
                          selectedFileType === 'pdf'
                        }
                        loadingText="Running Report&hellip;"
                        leftIcon={<ArrowForwardIcon />}
                        onClick={() =>
                          handleRunClimatologyReport(location, 'pdf')
                        }
                        colorScheme="blue"
                      >
                        Download {report.type}.pdf
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </Stack>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <>
      <Stack spacing={4}>
        <Stack
          spacing={2}
          direction="row"
          sx={{ justifyContent: 'space-between' }}
        >
          <Heading as="h5" size="sm">
            Download your reports
          </Heading>
          {!isMultiLocation && locations?.length > 1 && (
            <Stack spacing={4} direction="row">
              <ButtonGroup size={'sm'} spacing="2">
                <Button
                  isLoading={isRunningReport && selectedFileType === 'xlsx' && downloadAll}
                  loadingText="Running Report&hellip;"
                  leftIcon={<ArrowDownIcon />}
                  onClick={() =>
                    handleRunClimatologyReport(undefined, 'xlsx', true)
                  }
                  colorScheme="green"
                >
                  Download all {report.type}.xlsx
                </Button>

                <Button
                  isLoading={isRunningReport && selectedFileType === 'pdf' && downloadAll}
                  loadingText="Running Report&hellip;"
                  leftIcon={<ArrowForwardIcon />}
                  onClick={() =>
                    handleRunClimatologyReport(undefined, 'pdf', true)
                  }
                  colorScheme="blue"
                >
                  Download all {report.type}.pdf
                </Button>
              </ButtonGroup>
            </Stack>
          )}
        </Stack>
        {!isMultiLocation ? (
          <DatasetList locations={locations} />
        ) : (
          <Stack spacing={4} direction="row">
            <Button
              isLoading={isRunningReport && selectedFileType === 'xlsx'}
              loadingText="Running Report&hellip;"
              leftIcon={<ArrowDownIcon />}
              onClick={() => handleRunClimatologyReport(undefined, 'xlsx')}
              colorScheme="green"
            >
              Download {report.type}.xlsx
            </Button>
            {report.type !== 'custom' && (
              <Button
                isLoading={isRunningReport && selectedFileType === 'pdf'}
                loadingText="Running Report&hellip;"
                leftIcon={<ArrowForwardIcon />}
                onClick={() => handleRunClimatologyReport(undefined, 'pdf')}
                colorScheme="blue"
              >
                Download {report.type}.pdf
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      <SnowIceBoundsModal
        report={report}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={handleSnowIceBoundsSubmit}
      />
    </>
  );
};

export default ReportDownloadList;
