import React, { FunctionComponent, useMemo, useState } from 'react';
import { FormControl, FormLabel, Input, Stack, Button } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import ReportContext from '../context/reportContext';
import { Company, Location } from '../types';
import ClientAutoComplete from './ClientAutoComplete';
import { useAuth } from '../context/authContext';

interface ClientSelectProps {
  onSubmit: (locations: Location[]) => void;
}

const ClientSelect: FunctionComponent<ClientSelectProps> = ({ onSubmit }) => {
  const reportContext = React.useContext(ReportContext);
  if (!reportContext) {
    throw new Error('ReportForm must be used within a ReportContext');
  }

  const { axios } = useAuth();
  const { report, setReport } = reportContext;
  const [loading, setLoading] = useState(false);
  const canSubmit = useMemo(() => {
    return (
      report.client.firstName.trim().length !== 0 &&
      report.client.lastName.trim().length !== 0 &&
      report.client.companyName.trim().length !== 0
    );
  }, [report]);

  const handleClientChange = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReport((report) => ({
      ...report,
      client: {
        ...report.client,
        [key]: e.target.value,
      },
    }));
  };

  const handleCompanySelected = (company: Company) => {
    setReport((report) => ({
      ...report,
      client: {
        ...report.client,
        companyId: company.company_id,
        companyName: company.name,
      },
    }));

    Promise.all([
      axios.post('/cst/locations/company-locations', {
        companyId: company.company_id,
      }),
      /*
      axios.get('/companies/products', {
        params: {
          companyId: company.company_id,
        },
      }),
      */
    ]).then(([locationRes]) => {
      // Locations
      const locations: Location[] = locationRes.data.data.locations ?? [];
      //const products: string[] = productsRes.data.products ?? [];
      setReport((report) => ({
        ...report,
        locations,
        client: {
          ...report.client,
          products: [
            {
              name: 'Storm Alert',
            },
            {
              name: 'Certified Snowfall Totals',
            },
            {
              name: 'Forecast Management System',
            },
          ],
        },
      }));
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit([]);
    setLoading(false);
  };

  return (
    <Stack spacing={4}>
      <FormControl id="requestnames" isRequired>
        <FormLabel>Prepared For</FormLabel>
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <Input
              value={report.client.firstName}
              onChange={(e) => handleClientChange('firstName', e)}
              placeholder="First"
            />
            <Input
              value={report.client.lastName}
              onChange={(e) => handleClientChange('lastName', e)}
              placeholder="Last"
            />
          </Stack>
          {/*}
          <Input
            value={report.client.companyName}
            onChange={(e) => handleClientChange('companyName', e)}
            placeholder="Company Name"
          />
          */}

          <ClientAutoComplete
            value={report.client.companyName}
            onSelect={handleCompanySelected}
            onChange={(e, value) => {
              handleClientChange('companyName', e);
            }}
          />
        </Stack>
      </FormControl>

      <Stack spacing={10}>
        <Button
          leftIcon={<ArrowForwardIcon />}
          onClick={handleSubmit}
          disabled={!canSubmit || loading}
          bg={'blue.400'}
          color={'white'}
          _hover={{
            bg: 'blue.500',
          }}
        >
          Report Settings
        </Button>
      </Stack>
    </Stack>
  );
};
export default ClientSelect;
