import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  Input,
} from '@chakra-ui/react';
import { round } from '../helpers';

export type UpperBounds = Array<string | number | undefined>;
export interface ChangedBoundsParams {
  upperBounds: UpperBounds;
  lowerBounds: Array<number> | null;
  labels: Array<string> | undefined;
  isValid: boolean;
}

interface SnowIceBoundsTableProps {
  isEditing: boolean;
  mode: 'snow' | 'ice';
  upperBounds: UpperBounds;
  labels?: Array<string>;
  onChange?: (params: ChangedBoundsParams) => void;
}

const SnowIceBoundsTable: FunctionComponent<SnowIceBoundsTableProps> = ({
  mode,
  labels: _labels,
  upperBounds: _upperBounds,
  onChange: _onChange,
  isEditing,
}) => {
  const stepSize = useMemo(() => (mode === 'snow' ? 0.1 : 0.01), [mode]);
  const [labels, setLabels] = useState<Array<string> | undefined>(_labels);
  const [upperBounds, setUpperBounds] = useState<UpperBounds>(_upperBounds);
  const [invalidIndexes, setInvalidIndexes] = useState<Array<number>>([]);

  const precision = useMemo(() => (mode === 'snow' ? 1 : 2), [mode]);

  const calculateInvalidIndexes = useCallback((bounds: typeof upperBounds) => {
    const invalidIndexes: number[] = [];
    bounds.forEach((bound, index) => {
      if (bound === undefined) {
        invalidIndexes.push(index);
        return;
      }
      if (typeof bound === 'string') {
        bound = Number(bound);
      }
      if (isNaN(bound)) {
        invalidIndexes.push(index);
        return;
      }
      const valid = bounds.slice(0, index).every((previousBound) => {
        previousBound = Number(previousBound);
        if (bound && !isNaN(previousBound)) {
          return bound > previousBound;
        }
        return true;
      });
      if (!valid) {
        invalidIndexes.push(index);
      }
    });
    return invalidIndexes;
  }, []);

  useEffect(() => {
    setUpperBounds(_upperBounds);
  }, [_upperBounds]);

  useEffect(() => {
    setInvalidIndexes(calculateInvalidIndexes(upperBounds));
  }, [calculateInvalidIndexes, upperBounds]);

  const onChange = useCallback(
    (upperBounds: Array<string | number | undefined>) => {
      if (!_onChange) {
        return;
      }

      // Calculate new lower bounds
      const lowerBounds = upperBounds.map((upperBound, index) => {
        if (upperBound === undefined || upperBound === '') {
          return NaN;
        }
        if (typeof upperBound === 'string') {
          upperBound = Number(upperBound);
        }

        if (index === 0) {
          return upperBound;
        }
        return upperBound + stepSize;
      });

      //console.log({ lowerBounds, upperBounds });

      const isValid = calculateInvalidIndexes(upperBounds).length === 0;
      _onChange({ lowerBounds, upperBounds, labels, isValid });
    },
    [_onChange, calculateInvalidIndexes, stepSize, labels]
  );

  const handleChange = useCallback(
    (stringValue: string, value: number, index: number) => {
      const newUpperBounds = [...upperBounds];
      newUpperBounds[index] = stringValue;
      setUpperBounds(newUpperBounds);
      onChange(newUpperBounds);
    },
    [upperBounds, onChange]
  );

  const getLowerBound = useCallback(
    (index: number) => {
      if (index === 0) {
        // We'll show the label in a different column when the labels were provided
        return labels ? '' : 'Coatings';
      } else if (index > 0) {
        let previousUpperBound: string | number | undefined =
          upperBounds[index - 1];

        if (typeof previousUpperBound === 'string') {
          previousUpperBound = Number(previousUpperBound);
        }

        if (
          typeof previousUpperBound === 'number' &&
          !isNaN(previousUpperBound)
        ) {
          /**
           * If the index is 1 we don't want to increment the previous bound because
           * the previous bound is < and the the following bounds are treated as >=
           *
           * For example:
           * < 0.1
           * 0.1 - 0.9 // We want this bound to start at .1 not 0.2
           * 1.0 - 1.9
           * 2.0 - 2.9
           * >= 3.0
           *
           */
          return round(
            previousUpperBound + (index === 1 ? 0 : stepSize),
            precision
          );
        }
        return '-';
      }
    },
    [upperBounds, stepSize, precision, labels]
  );

  const handleRemoveBound = (index: number) => {
    const newUpperBounds = upperBounds.filter((_, i) => i !== index);
    setUpperBounds(newUpperBounds);
    if (labels) {
      const newLabels = labels.filter((_, i) => i !== index);
      setLabels(newLabels);
    }
    onChange(newUpperBounds);
  };

  const handleAddBound = (index: number) => {
    let nextValue: string = '';
    if (
      upperBounds.length > 0 &&
      upperBounds[upperBounds.length - 1] !== undefined
    ) {
      let previousUpperBound = Number(upperBounds[upperBounds.length - 1]);
      if (!isNaN(previousUpperBound)) {
        nextValue = (previousUpperBound + stepSize).toString();
      }
    }

    const copy = [...upperBounds];
    copy.splice(index + 1, 0, nextValue);

    if (labels) {
      const copyLabels = [...labels];
      copyLabels.splice(index + 1, 0, '');
      setLabels(copyLabels);
    }

    setUpperBounds(copy);
    onChange(copy);
  };

  const LastBound = () => {
    let text = '-';
    const lastBound = upperBounds[upperBounds.length - 1];
    if (lastBound !== undefined) {
      const n = Number(lastBound);
      if (!isNaN(n)) {
        text = round(n + stepSize, precision).toString();
      }
    }
    return <Text>{text}</Text>;
  };

  const valueForInput = (value: string | number | undefined) => {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      return isNaN(value) ? '' : value.toString();
    }
  };

  return (
    <Table size={'sm'}>
      <Tbody>
        {upperBounds.map((upperBound, index) => (
          <Tr key={`bound${index}`}>
            {!isEditing && labels && (
              <Td>
                <Text fontSize="sm">{labels[index]}</Text>
              </Td>
            )}
            {isEditing && labels && (
              <Td>
                <Input
                  size={'sm'}
                  value={labels[index] ?? ''}
                  onChange={(e) => {
                    setLabels((labels) =>
                      labels?.map((label, i) => {
                        if (index === i) {
                          return e.target.value;
                        }
                        return label;
                      })
                    );
                  }}
                />
              </Td>
            )}
            <Td>
              <Text>{getLowerBound(index)}</Text>
            </Td>

            <Td>
              <Text>{index === 0 ? '<' : 'To'}</Text>
            </Td>
            {!isEditing && (
              <Td>
                <Text>{upperBound ?? '-'}</Text>
              </Td>
            )}

            {isEditing && (
              <>
                <Td>
                  <NumberInput
                    min={0}
                    size="sm"
                    step={stepSize}
                    //value={upperBound ? round(upperBound, precision) : ''}
                    value={valueForInput(upperBound)}
                    isInvalid={invalidIndexes.includes(index)}
                    onChange={(valueAsString, valueAsNumber) =>
                      handleChange(valueAsString, valueAsNumber, index)
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>

                <Td>
                  {index + 1 === upperBounds.length ? (
                    <>
                      <IconButton
                        disabled={
                          upperBounds.length >= (mode === 'snow' ? 10 : 7)
                        }
                        onClick={() => handleAddBound(index)}
                        aria-label="Add Bound"
                        icon={<AddIcon />}
                      />
                    </>
                  ) : (
                    <>
                      {index > 1 && (
                        <IconButton
                          onClick={() => handleRemoveBound(index)}
                          aria-label="Remove Bound"
                          icon={<DeleteIcon />}
                        />
                      )}
                    </>
                  )}
                </Td>
              </>
            )}
          </Tr>
        ))}
        {upperBounds.length > 0 && (
          <Tr>
            {labels && (
              <Td>
                {isEditing ? (
                  <Input
                    size={'sm'}
                    value={labels[labels.length - 1] ?? ''}
                    onChange={(e) => {
                      setLabels((labels) =>
                        labels?.map((label, i) => {
                          if (labels.length - 1 === i) {
                            return e.target.value;
                          }
                          return label;
                        })
                      );
                    }}
                  />
                ) : (
                  labels[labels.length - 1]
                )}
              </Td>
            )}

            <Td />
            <Td>&gt;=</Td>
            <Td>
              <LastBound />
            </Td>
            {isEditing && <Td></Td>}
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default SnowIceBoundsTable;
