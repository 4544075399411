import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Stack,
  Button,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  useToast,
  Center,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  ButtonGroup,
  HStack,
} from '@chakra-ui/react';
import ExcelJS from 'exceljs';
import {
  AddIcon,
  ArrowForwardIcon,
  AttachmentIcon,
  DeleteIcon,
  EditIcon,
} from '@chakra-ui/icons';
import { FaCheckCircle, FaSearchLocation } from 'react-icons/fa';
import StateSelect from '../components/StateSelect';
import { TargetBox } from '../components/TargetBox';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReportContext from '../context/reportContext';
import { Location } from '../types';
import { EMPTY_LOCATION } from '../pages/Snowtistics';
import { AxiosResponse } from 'axios';
import { useAuth } from '../context/authContext';
import ZipcodeAutoComplete from './ZipcodeAutoComplete';
import CityAutoComplete from './CityAutoComplete';

interface CstLocationsProps {
  onSubmit: (locations: Location[]) => void;
}

const CstLocations: FunctionComponent<CstLocationsProps> = ({ onSubmit }) => {
  const reportContext = React.useContext(ReportContext);
  if (!reportContext) {
    throw new Error('ReportForm must be used within a ReportContext');
  }
  const { axios } = useAuth();
  const { report, setReport } = reportContext;
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [fileProcessing, setFileProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState<Location[]>(
    []
  );

  const canSubmit = useMemo(() => {
    return (
      report.locations.length > 0 &&
      report.locations.every((location) => {
        return location.location_id;
      })
    );
  }, [report]);

  const handleAddLocation = (index: number) => {
    setReport((report) => {
      const copy = Object.assign({}, report, {
        locations: [...report.locations],
      });
      copy.locations.splice(index + 1, 0, { ...EMPTY_LOCATION });
      return copy;
    });
  };

  const handleRemoveLocation = (index: number) => {
    setReport((report) => {
      const copy = Object.assign({}, report);
      if (copy.locations.length > 1) {
        copy.locations = copy.locations.filter((_, idx) => idx !== index);
      } else {
        copy.locations = [{ ...EMPTY_LOCATION }];
      }
      return copy;
    });
  };

  useEffect(() => {
    if (report.endYear && report.timeframe) {
      const timeframeLookup = {
        '5-Year': 5,
        '10-Year': 10,
        '15-Year': 15,
        '20-Year': 20,
        '25-Year': 25,
        '30-Year': 30,
      };
      const startYear = report.endYear - timeframeLookup[report.timeframe];
      setReport((report) => ({ ...report, startYear }));
    }
  }, [report.endYear, report.timeframe, setReport]);

  const handleLocationChange = (
    index: number,
    key: keyof Location,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setReport((report) => {
      const copy = Object.assign({}, report);
      copy.locations = copy.locations.map((location, i) => {
        if (i === index) {
          return Object.assign({}, location, { [key]: e.target.value });
        }
        return location;
      });
      return copy;
    });
  };

  const handleLocationSelected = (index: number, location: Location) => {
    setReport((report) => {
      const copy = Object.assign({}, report);
      copy.locations = copy.locations.map((l, i) => {
        if (i === index) {
          return Object.assign({}, location);
        }
        return l;
      });
      return copy;
    });
  };

  const handleSubmit = async () => {
    if (reportContext.cstLocations.length > 0) {
      // Check to see if we already have these locations in our context:
      const matchedLocationIds = new Set(
        reportContext.cstLocations.map((l) => l.location_id)
      );
      const locationsWithoutMatch = report.locations.filter(
        (l) => !matchedLocationIds.has(l.location_id)
      );
      if (locationsWithoutMatch.length === 0) {
        onSubmit(reportContext.cstLocations);
        return;
      }
    }

    setLoading(true);

    interface LocationCheck {
      location_id: number;
      isComplete: boolean;
      needsReview: boolean;
      periods: { period: number; isComplete: boolean }[];
    }

    let checkSeasonResponse:
      | AxiosResponse<{ data: { locations: LocationCheck[] } }>
      | undefined = await axios.post('/cst/locations/check-seasons', {
      locations: report.locations.map(({ location_id }) => Number(location_id)),
      periodType: report.periodType === 'Calendar Year' ? 'year' : 'season',
      start: report.startYear,
      end: report.endYear,
      precipType: report.precip,
    });

    const lookup = new Map<number, LocationCheck>();
    for (const location of checkSeasonResponse?.data.data.locations ?? []) {
      lookup.set(location.location_id, location);
    }

    const locations = report.locations.map((location) => {
      const seasonCheck = lookup.get(Number(location.location_id));
      return {
        ...location,
        isComplete: seasonCheck?.isComplete ?? false,
        needsReview: seasonCheck?.needsReview ?? false,
        periods:
          seasonCheck?.periods.map((periodCheck) => {
            return {
              ...periodCheck,
              isComplete: periodCheck.isComplete,
              source: location.location_id,
              sourceType: 'primary',
            };
          }) ?? [],
      } as Location;
    });

    onSubmit(locations);
    setLoading(false);
  };

  async function handleFileDrop(item: { files?: File[] }) {
    if (item.files === undefined || item.files.length === 0) {
      return;
    }
    setFileProcessing(true);
    const file = item.files[0];
    const data = await file.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    try {
      await workbook.xlsx.load(data);
    } catch (error) {
      toast({
        title: 'Attempt to parse file failed',
        status: 'error',
        isClosable: true,
      });
      setFileProcessing(false);
      return;
    }
    if (workbook.worksheets.length === 0) {
      //no idea how you would get this, i dont think its possible to make an excel file without any sheets
      toast({
        title: 'Excel file has no sheets',
        status: 'error',
        isClosable: true,
      });
      setFileProcessing(false);
      return;
    }
    let locations: Location[] = [];
    const sheet = workbook.worksheets[0];
    const columns: { city?: number; state?: number; zipcode?: number } = {};
    for (let i = 1; i < sheet.rowCount + 1; i++) {
      if (i === 1) {
        for (let j = 1; j < sheet.columnCount + 1; j++) {
          const label = sheet
            .getCell(i, j)
            .value?.toString()
            .trim()
            .toLowerCase();

          if (label === 'city') {
            columns.city = j;
          } else if (label === 'state') {
            columns.state = j;
          } else if (
            label === 'zipcode' ||
            label === 'zip' ||
            label === 'postal code'
          ) {
            columns.zipcode = j;
          }
        }
        if (!columns.city || !columns.state || !columns.zipcode) {
          // Auto-detect columns failed, revert to expecting city, state, zipcode
          columns.city = 1;
          columns.state = 2;
          columns.zipcode = 3;
        }
        continue;
      }

      const city = sheet.getCell(i, columns.city).value?.toString() ?? '';
      const state = sheet.getCell(i, columns.state).value?.toString() ?? '';
      const zipcode = sheet.getCell(i, columns.zipcode).value?.toString() ?? '';
      if (city && state && zipcode) {
        locations.push({
          city,
          state,
          zipcode,
        });
      }
    }

    // Match the uploaded locations to a CST location
    // TODO: Do these in batches?
    locations = await Promise.all(
      locations.map((location) => matchLocation(location, true))
    );

    setReport((report) => ({ ...report, locations }));
    setFileProcessing(false);
    setTabIndex(0);
  }

  const handleDownload = () => {
    window.location.pathname = '/excel-templates/BulkLocations.xlsx';
  };

  const handleEditClick = (index: number) => {
    setReport((report) => ({
      ...report,
      locations: report.locations.map((location, i) => {
        if (i === index) {
          return {
            ...location,
            location_id: undefined,
          };
        }
        return location;
      }),
    }));
  };

  const getCSTLocationMatches = (
    location: Location,
    useGeocode = false
  ): Promise<Location[]> => {
    const params: any = {};
    if (location.city) {
      params.city = location.city;
    }
    if (location.state) {
      params.state = location.state;
    }
    if (location.zipcode) {
      params.zipcode = location.zipcode;
    }

    let res: Promise<AxiosResponse<any, any>>;
    if (!useGeocode) {
      res = axios.get('/cst/locations', {
        params,
      });
    } else {
      res = axios.post('/cst/locations/match', params);
    }

    return res.then(({ data }) => {
      return data.data.cstLocations ?? [];
    });
  };

  const searchForCSTLocations = (location: Location) => {
    getCSTLocationMatches(location, true).then((cstLocations) => {
      setLocationSuggestions(cstLocations.slice(0, 5));
    });
  };

  const matchLocation = (location: Location, useGeocode: boolean = true) => {
    return getCSTLocationMatches(location, true).then((matches) => {
      return matches.length > 0 ? matches[0] : location;
    });
  };

  return (
    <Stack spacing={4}>
      <FormControl id="locations" isRequired>
        <FormLabel>Locations</FormLabel>
        <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Manual</Tab>
            <Tab>Bulk Upload</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack>
                {report.locations.map((location, index) => (
                  <Stack
                    spacing={4}
                    direction="row"
                    key={`location${index}`}
                    justifyContent="space-between"
                  >
                    {location.location_id ? (
                      <HStack>
                        <Icon as={FaCheckCircle} color="green" />
                        <Text>
                          {location.city}, {location.state} {location.zipcode}
                        </Text>
                      </HStack>
                    ) : (
                      <>
                        <CityAutoComplete
                          state={location.state}
                          value={location.city}
                          onSelect={(location) =>
                            handleLocationSelected(index, location)
                          }
                          onChange={(e) => {
                            handleLocationChange(index, 'city', e);
                          }}
                        />

                        <StateSelect
                          value={location.state}
                          onChange={(e) => {
                            handleLocationChange(index, 'state', e);
                          }}
                        />

                        <ZipcodeAutoComplete
                          value={location.zipcode}
                          onSelect={(location) =>
                            handleLocationSelected(index, location)
                          }
                          onChange={(e) => {
                            handleLocationChange(index, 'zipcode', e);
                          }}
                        />
                      </>
                    )}

                    <ButtonGroup>
                      {location.location_id == null ? (
                        <Menu isLazy closeOnSelect>
                          <MenuButton
                            onClick={() => searchForCSTLocations(location)}
                            as={IconButton}
                            icon={<FaSearchLocation />}
                          ></MenuButton>
                          <MenuList>
                            {locationSuggestions.map((location) => (
                              <MenuItem
                                onClick={() =>
                                  handleLocationSelected(index, location)
                                }
                                key={location.location_id}
                                value={location.location_id}
                              >
                                {location.city}, {location.state}{' '}
                                {location.zipcode}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      ) : (
                        <IconButton
                          aria-label="Edit"
                          icon={<EditIcon />}
                          onClick={() => {
                            handleEditClick(index);
                          }}
                        />
                      )}

                      <IconButton
                        disabled={
                          report.locations.length === 1 &&
                          !report.locations[0].city &&
                          !report.locations[0].state &&
                          !report.locations[0].zipcode
                        }
                        onClick={() => handleRemoveLocation(index)}
                        aria-label="Remove Location"
                        icon={<DeleteIcon />}
                      />
                      <IconButton
                        onClick={() => handleAddLocation(index)}
                        aria-label="Add Location"
                        icon={<AddIcon />}
                      />
                    </ButtonGroup>
                  </Stack>
                ))}
              </Stack>
            </TabPanel>
            <TabPanel>
              {fileProcessing ? (
                <Center>
                  <Spinner size="xl" />
                </Center>
              ) : (
                <Stack spacing={4}>
                  <Text>
                    Download and complete the bulk upload Excel template to bulk
                    import locations.
                  </Text>
                  <Button
                    onClick={handleDownload}
                    variant="outline"
                    size="sm"
                    leftIcon={<AttachmentIcon />}
                  >
                    Download Template
                  </Button>

                  <DndProvider backend={HTML5Backend}>
                    <TargetBox onDrop={handleFileDrop} />
                  </DndProvider>
                </Stack>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormControl>
      <Stack spacing={4}>
        <Button
          variant={'ghost'}
          colorScheme="gray"
          onClick={() => {
            if (
              window.confirm('Are you sure you want to clear these locations?')
            ) {
              setReport((report) => ({
                ...report,
                locations: [{ ...EMPTY_LOCATION }],
              }));
            }
          }}
        >
          Clear Locations
        </Button>
        <Button
          leftIcon={<ArrowForwardIcon />}
          isLoading={loading}
          loadingText="Matching locations&hellip;"
          onClick={handleSubmit}
          disabled={!canSubmit || loading}
          bg={'blue.400'}
          color={'white'}
          _hover={{
            bg: 'blue.500',
          }}
        >
          Match Locations
        </Button>
      </Stack>
    </Stack>
  );
};
export default CstLocations;
