import React, {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  List,
  ListItem,
  Portal,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useAuth } from '../context/authContext';
import { Location } from '../types';

export interface ZipcodeAutoCompleteProps {
  onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
  onSelect?: (location: Location) => void;
  value?: string;
}

const ZipcodeAutoComplete = ({
  value,
  onChange,
  onSelect,
}: ZipcodeAutoCompleteProps) => {
  const { axios } = useAuth();
  const [items, setItems] = useState<Location[]>([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = (e: any, item: Location) => {
    if (onSelect) {
      onSelect(item);
    }
    setHasFocus(false);
    inputRef.current?.blur();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.target.value);
    }
  };

  useEffect(() => {
    if (value && value.length >= 5) {
      axios
        .get('/cst/locations', {
          params: {
            zipcode: value,
            limit: 25,
          },
        })
        .then((res) => {
          const items = res.data.data.locations ?? [];
          setItems(items);
          setSelectedIndex(-1);
        });
    } else {
      setItems([]);
    }
  }, [value, hasFocus, axios]);

  const handleKeyDown = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      if (selectedIndex >= 0) {
        const item = items[selectedIndex];
        handleSelect(e, item);
      }
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let step = 0;
      if (e.key === 'ArrowDown') {
        step = 1;
      } else if (e.key === 'ArrowUp') {
        step = -1;
      }
      setSelectedIndex((selectedIndex) => {
        const newIndex = selectedIndex + step;
        return newIndex < 0 ? items.length - 1 : newIndex % items.length;
      });

      if (step === 0) {
        return;
      }
    } else if (e.key === 'Escape') {
      setSelectedIndex(-1);
    }
  };

  const handleBlur = (e: any) => {
    setHasFocus(false);
  };

  const isOpen = useMemo(() => {
    return hasFocus && items.length > 0;
  }, [hasFocus, items]);

  return (
    <Popover returnFocusOnClose={false} autoFocus={false} isOpen={isOpen} placement='right'>
      <PopoverTrigger>
        <Input
          placeholder="Zip"
          ref={inputRef}
          autoComplete="off"
          value={value ?? ''}
          onChange={handleChange}
          onFocus={() => setHasFocus(true)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <List onKeyDown={handleKeyDown} ref={listRef}>
              {items.map((item, index) => (
                <ListItem
                  className="list-item"
                  key={item.location_id}
                  onClick={(e) => handleSelect(e, item)}
                  onBlur={handleBlur}
                  onMouseDown={(e) => e.preventDefault()}
                  _hover={{ bg: 'gray.100' }}
                  sx={{
                    padding: '0.5rem',
                    cursor: 'pointer',
                    backgroundColor:
                      selectedIndex === index ? 'gray.100' : 'transparent',
                  }}
                >
                  <Flex justifyContent="space-between">
                    <Text>{item.zipcode}</Text>
                    <Text>
                      {item.city}, {item.state}
                    </Text>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ZipcodeAutoComplete;
