import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Stack,
  Button,
  Select,
  Checkbox,
  Alert,
  AlertIcon,
  CheckboxGroup,
} from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';

import ReportContext from '../context/reportContext';
import {
  ReportPrecipType,
  ReportTimeFrame,
  ReportType,
  ReportPeriodType,
  Location,
} from '../types';

interface ReportTypeOption {
  value: ReportType;
  label: string;
}

const REPORT_PARAMS: { value: ReportPrecipType; label: string }[] = [
  { value: 'snow', label: 'Snow' },
  { value: 'ice', label: 'Ice' },
  { value: 'snowice', label: 'Snow & Ice' },
];
const REPORT_TYPES: ReportTypeOption[] = [
  { value: '30-year-standard', label: '30-Year Standard' },
  { value: 'raw', label: 'Raw Data Only' },
  { value: 'average', label: 'Average(s) Only' },
  { value: 'custom', label: 'Custom' },
];
const WINTER_REPORT_TYPES: ReportTypeOption[] = [
  { value: 'seasonal', label: 'Seasonal Totals' },
  { value: 'month-season', label: 'Only Monthly & Seasonal Totals Only' },
];
const CALENDAR_REPORT_TYPES: ReportTypeOption[] = [
  { value: 'calendar-year-totals', label: 'Calendar Year Totals' },
  {
    value: 'calendar-year-monthly-totals-only',
    label: 'Calendar Year Monthly Totals Only',
  },
];
const SNOWTISTICS_REPORT_TYPES: { value: ReportType; label: string }[] = [
  { value: 'snowtistics-lite', label: 'Snowtistics Lite' },
  { value: 'snowtistics', label: 'Snowtistics' },
];

const REPORT_TIMEFRAMES: ReportTimeFrame[] = ['5-Year', '10-Year', '15-Year'];
const REPORT_PERIODS: ReportPeriodType[] = ['Winter Season', 'Calendar Year'];

interface ReportFormProps {
  onSubmit: (locations: Location[]) => void;
}

const range = (start: number, end: number) => {
  if (start <= end) {
    return Array(end - start + 1)
      .fill(0)
      .map((_, idx) => start + idx);
  } else {
    return Array(start - end + 1)
      .fill(0)
      .map((_, idx) => start - idx);
  }
};

const ReportForm: FunctionComponent<ReportFormProps> = ({ onSubmit }) => {
  const reportContext = React.useContext(ReportContext);
  if (!reportContext) {
    throw new Error('ReportForm must be used within a ReportContext');
  }
  const { report, setReport } = reportContext;
  const [loading, setLoading] = useState(false);
  const canSubmit = useMemo(() => {
    return (
      report.client.firstName.trim().length !== 0 &&
      report.client.lastName.trim().length !== 0 &&
      report.client.companyName.trim().length !== 0
    );
  }, [report]);

  const reportTimeframes = useMemo(() => {
    const reportTimeframes = [...REPORT_TIMEFRAMES];
    if (report.precip === 'snow') {
      reportTimeframes.push('20-Year');
      reportTimeframes.push('25-Year');
      reportTimeframes.push('30-Year');
    }
    return reportTimeframes;
  }, [report.precip]);

  const endYears = useMemo(() => {
    const today = new Date();
    const todayAsNumber = today.getTime();
    const currentYear = today.getFullYear();
    const priorYear = today.getFullYear() - 1;
    const from = new Date();
    const to = new Date();

    let endYears: number[] = [];
    if (report.periodType === 'Winter Season') {
      if (report.timeframe === '5-Year') {
        if (
          todayAsNumber > from.setMonth(0, 1) &&
          todayAsNumber < to.setMonth(3, 15)
        ) {
          endYears = range(priorYear, 2010);
        } else if (
          todayAsNumber > from.setMonth(3, 16) &&
          todayAsNumber < to.setMonth(11, 31)
        ) {
          endYears = range(currentYear, 2010);
        }
      } else if (report.timeframe === '10-Year') {
        if (
          todayAsNumber > from.setMonth(0, 1) &&
          todayAsNumber < to.setMonth(3, 15)
        ) {
          endYears = range(priorYear, 2015);
        } else if (
          todayAsNumber > from.setMonth(3, 16) &&
          todayAsNumber < to.setMonth(11, 31)
        ) {
          endYears = range(currentYear, 2015);
        }
      } else if (report.timeframe === '15-Year') {
        if (
          todayAsNumber > from.setMonth(0, 1) &&
          todayAsNumber < to.setMonth(3, 15)
        ) {
          endYears = range(priorYear, 2020);
        } else if (
          todayAsNumber > from.setMonth(3, 16) &&
          todayAsNumber < to.setMonth(11, 31)
        ) {
          endYears = range(currentYear, 2020);
        }
      }
    } else if (report.periodType === 'Calendar Year') {
      if (report.timeframe === '5-Year') {
        endYears = range(priorYear, 2010);
      } else if (report.timeframe === '10-Year') {
        endYears = range(priorYear, 2015);
      } else if (report.timeframe === '15-Year') {
        endYears = range(priorYear, 2020);
      }
    }
    if (!endYears.includes(currentYear)) {
      /**
       * We previosuly didn't allow "season to date" reports, but now we do, so
       * we'll add the current year to list of end years if it's not already.
       */
      endYears.unshift(currentYear);
    }
    return endYears;
  }, [report.timeframe, report.periodType]);

  const reportTypes = useMemo(() => {
    if (report.periodType === 'Calendar Year') {
      return [
        ...REPORT_TYPES,
        ...CALENDAR_REPORT_TYPES,
        ...SNOWTISTICS_REPORT_TYPES,
      ];
    } else {
      return [
        ...REPORT_TYPES,
        ...WINTER_REPORT_TYPES,
        ...SNOWTISTICS_REPORT_TYPES,
      ];
    }
  }, [report.periodType]);

  const isSeasonToDate = useMemo(() => {
    const now = new Date();
    return (
      now.getFullYear() === report.endYear &&
      ((report.periodType === 'Winter Season' &&
        now.getTime() < new Date().setMonth(3, 15)) ||
        report.periodType === 'Calendar Year')
    );
  }, [report.periodType, report.endYear]);

  const handlePrecipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReport((report) =>
      Object.assign({}, report, { precip: e.target.value })
    );
  };
  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReport((report) => Object.assign({}, report, { type: e.target.value }));
  };
  const handleTimeFrameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReport((report) =>
      Object.assign({}, report, { timeframe: e.target.value })
    );
  };

  const handleSeasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReport((report) =>
      Object.assign({}, report, { periodType: e.target.value })
    );
  };

  const handleEndYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReport((report) =>
      Object.assign({}, report, { endYear: Number(e.target.value) })
    );
  };

  useEffect(() => {
    if (report.endYear && report.timeframe) {
      const timeframeLookup = {
        '5-Year': 5,
        '10-Year': 10,
        '15-Year': 15,
        '20-Year': 20,
        '25-Year': 25,
        '30-Year': 30,
      };
      const startYear = report.endYear - timeframeLookup[report.timeframe];
      setReport((report) => ({ ...report, startYear }));
    }
  }, [report.endYear, report.timeframe, setReport]);

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit([]);
    setLoading(false);
  };

  const show30YearStdCheckbox = useMemo(() => {
    return (
      [
        'average',
        'calendar-year-monthly-totals-only',
        'calendar-year-totals',
        'month-season',
        'seasonal',
      ] as ReportType[]
    ).includes(report.type);
  }, [report.type]);

  return (
    <Stack spacing={4}>
      <FormControl id="requesttype" isRequired>
        <FormLabel>Report Type</FormLabel>
        <Stack>
          <Select onChange={handleTypeChange} value={report.type}>
            {reportTypes.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
          {show30YearStdCheckbox && (
            <Checkbox
              isChecked={report.includeStandard}
              onChange={(e) => {
                setReport((report) => ({
                  ...report,
                  includeStandard: e.target.checked,
                }));
              }}
            >
              Include 30-Year Standard Statistics
            </Checkbox>
          )}
        </Stack>
      </FormControl>

      {report.type === 'custom' && (
        <FormControl id="requestcustom" isRequired>
          <FormLabel>Columns</FormLabel>
          <CheckboxGroup
            defaultValue={report.customColumns ?? []}
            onChange={(value) => {
              setReport((report) => ({
                ...report,
                customColumns: value as string[],
              }));
            }}
          >
            <Stack spacing={[1, 5]} direction={['column', 'row']}>
              <Checkbox value="standardSnowfall">
                30-Year Standard Snowfall
              </Checkbox>
              <Checkbox value="eventCountAverages">
                Event Count Averages
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </FormControl>
      )}

      {report.type !== '30-year-standard' && (
        <>
          <FormControl id="requestparam" isRequired>
            <FormLabel>Report Parameter</FormLabel>
            <Select onChange={handlePrecipChange} value={report.precip}>
              {REPORT_PARAMS.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="requestSeason" isRequired>
            <FormLabel>Season</FormLabel>
            <Select onChange={handleSeasonChange}>
              {REPORT_PERIODS.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl id="timeframe" isRequired>
            <FormLabel>Timeframe</FormLabel>
            <Select onChange={handleTimeFrameChange} value={report.timeframe}>
              {reportTimeframes.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="requestYear" isRequired>
            <FormLabel>End of Year</FormLabel>
            <Select onChange={handleEndYearChange} value={report.endYear}>
              {endYears.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          {isSeasonToDate && (
            <Alert status="warning">
              <AlertIcon />
              This selection will only be able to provide a season-to-date
              request
            </Alert>
          )}
        </>
      )}
      <Stack spacing={10}>
        <Button
          leftIcon={<ArrowForwardIcon />}
          onClick={handleSubmit}
          disabled={!canSubmit || loading}
          bg={'blue.400'}
          color={'white'}
          _hover={{
            bg: 'blue.500',
          }}
        >
          {report.locations.length === 0 ? 'Add Locations' : 'View Locations'}
        </Button>
      </Stack>
    </Stack>
  );
};
export default ReportForm;
