import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReportContext from '../context/reportContext';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverBody,
  Portal,
  PopoverContent,
  PopoverArrow,
  Divider,
  Flex,
  Spinner,
  Badge,
  Checkbox,
  FormLabel,
  FormControl,
  Input,
  FormHelperText,
  Alert,
  AlertDescription,
  PopoverHeader,
  PopoverFooter,
  useDisclosure,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  BestMatchLocationType,
  CstBestMatch,
  Location,
  LocationPeriod,
  ReportLocation,
} from '../types';
import {
  ArrowDownIcon,
  ArrowForwardIcon,
  ChevronDownIcon,
  Search2Icon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import { useAuth } from '../context/authContext';
import { AxiosResponse } from 'axios';
import { MdMoreVert, MdOutlineAutoFixHigh } from 'react-icons/md';
import ReconstructDatasetModal from './ReconstructDatasetModal';
import download from 'downloadjs';

interface SelectableLocation extends Location {
  selected: boolean;
}

interface GroupedLocation extends Location {
  groupName?: string;
  groupedLocations: Location[];
}

const CstDatasetList = ({
  onSubmit,
  onReportLocationsChange,
}: {
  onSubmit: (locations: ReportLocation[]) => void;
  onReportLocationsChange?: (reportLocations: ReportLocation[]) => void;
}) => {
  const reportContext = useContext(ReportContext);
  if (!reportContext) {
    throw new Error('CstDatasetList must be used within a ReportContext');
  }
  const { axios } = useAuth();
  const { report, cstLocations, bestMatches, setBestMatches } = reportContext;

  const locationSourcesDisclosure = useDisclosure();
  const reconstructDatasetDisclosure = useDisclosure();

  const [selectedLocation, setSelectedLocation] = useState<GroupedLocation>();
  const [isMatching, setIsMatching] = useState(false);

  const [loadingPreview, setLoadingPreview] = useState(false);
  const [previewingData, setPreviewingData] = useState(false);
  const [downloadingData, setDownloadingData] = useState(false);

  const [rawData, setRawData] = useState<(string | number)[][]>();
  const [showingFixMissingData, setShowingFixMissingData] = useState(false);

  const [groupableLocations, setGroupableLocations] =
    useState<SelectableLocation[]>();
  const [trustedLocationIds, setTrustedLocationIds] = useState<number[]>([]);

  const [groupedLocations, setGroupedLocations] = useState<GroupedLocation[]>(
    cstLocations.map((location) => ({ ...location, groupedLocations: [] }))
  );

  React.useEffect(() => {
    // Do we have all the best matches already?
    if (cstLocations.every((l) => bestMatches.has(l.location_id as number))) {
      return;
    }

    setIsMatching(true);
    // Take CST locations and find the best Climo and CST matches
    axios
      .post('/cst/locations/best-match', {
        locations: cstLocations.map((location) => location.location_id),
        start: report.startYear,
        end: report.endYear,
        periodType: report.periodType,
        precipType: report.precip,
      })
      .then((response: AxiosResponse<{ data: CstBestMatch[] }>) => {
        const { data } = response.data;
        const bestMatches = new Map<number, CstBestMatch>();
        for (const match of data) {
          bestMatches.set(match.location_id, match);
        }
        setBestMatches(bestMatches);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsMatching(false);
      });
  }, [axios, cstLocations, report, setBestMatches, bestMatches]);

  const getGroupableCstLocations = useCallback(
    (location: GroupedLocation) => {
      if (!location.location_id) {
        return [];
      }
      // Get the climo region for the CST location
      const climoRegionId = bestMatches.get(
        location.location_id
      )?.climo_region_id;
      if (!climoRegionId) {
        // Grouping is not possible if there is no climo region
        return [];
      }

      const alreadyGroupedLocationIds = groupedLocations
        .filter((groupedLocation) => {
          // Filter out the current location
          return groupedLocation.location_id !== location.location_id;
        })
        .map((groupedLocation) => {
          const locationIds = [];
          if (groupedLocation.groupedLocations.length > 0) {
            locationIds.push(groupedLocation.location_id);
          }
          locationIds.push(
            ...groupedLocation.groupedLocations.map(
              (groupedLocation) => groupedLocation.location_id
            )
          );
          return locationIds;
        })
        .flat();

      const locations: Location[] = [];
      // Look for other locations that have the same climo region
      bestMatches.forEach((match, key) => {
        if (
          match.climo_region_id === climoRegionId &&
          match.location_id !== location.location_id
        ) {
          // Add the location to the list of locations that can be grouped
          const cstLocation = cstLocations.find((l) => l.location_id === key);
          if (cstLocation && !alreadyGroupedLocationIds.includes(key)) {
            locations.push(cstLocation);
          }
        }
      });

      return locations;
    },
    [bestMatches, cstLocations, groupedLocations]
  );

  const getLocationName = (location?: Location) => {
    if (!location) {
      return '';
    }
    return `${location.city}, ${location.state} ${location.zipcode}`;
  };

  const reportLocations = useMemo((): ReportLocation[] => {
    const locationsInGroup = groupedLocations
      .map((l) => l.groupedLocations.map((l) => l.location_id))
      .flat();
    const filter = (location: GroupedLocation) => {
      return !locationsInGroup.includes(location.location_id);
    };

    return groupedLocations.filter(filter).map((location) => {
      const bestMatch = bestMatches.get(location.location_id as number);
      let name = getLocationName(location);
      if (location.groupedLocations.length > 0 && location.groupName) {
        name = location.groupName;
      }

      const periods: { [key: number]: string | number } = {};
      location.periods?.forEach((period) => {
        // A group of locations will use the climo region
        const source =
          location.groupedLocations.length > 0
            ? bestMatch?.climo_region_id
            : period.source;

        if (source == null) {
          console.warn(`Can't find source for ${period}`);
        }
        periods[period.period] = source as string | number;
      });

      return {
        name,
        periods,
        standard: bestMatch?.climo_region_id ?? undefined,
      };
    });
  }, [groupedLocations, bestMatches]);

  useEffect(() => {
    onReportLocationsChange?.(reportLocations);
  }, [onReportLocationsChange, reportLocations]);

  const handleCreateGroup = () => {
    if (!selectedLocation || !groupableLocations) {
      return;
    }
    const childLocations = groupableLocations.filter(
      (groupable) => groupable.selected
    );
    setGroupedLocations((groupedLocations) => {
      return groupedLocations.map((groupedLocation) => {
        if (groupedLocation.location_id === selectedLocation.location_id) {
          return {
            ...groupedLocation,
            groupedLocations: childLocations,
            groupName:
              groupedLocations.length > 0
                ? selectedLocation.groupName
                : undefined,
          };
        } else {
          return groupedLocation;
        }
      });
    });
    handleCloseGroupModal();
  };

  const handleCloseGroupModal = () => {
    setSelectedLocation(undefined);
    setGroupableLocations(undefined);
  };

  if (cstLocations.length === 0) {
    return (
      <Stack>
        <Heading as="h5" size="sm">
          No CST Datasets were found
        </Heading>
      </Stack>
    );
  }

  const getCustomCsvParams = (location: GroupedLocation) => {
    const mungedLocation: {
      periods: { [period: number]: number | string | null | undefined };
    } = {
      periods: {},
    };
    const isGrouped = location.groupedLocations.length > 0;
    const climoRegionId = bestMatches.get(
      location.location_id as number
    )?.climo_region_id;

    for (const period of location.periods ?? []) {
      mungedLocation.periods[period.period] =
        isGrouped && climoRegionId ? climoRegionId : period.source;
    }

    return {
      startYear: report.startYear,
      endYear: report.endYear,
      periodType: report.periodType,
      locations: [mungedLocation],
      columns: [
        {
          header: 'Start',
          field: 'date',
        },
        {
          header: 'End',
          field: 'end',
        },
        {
          header: 'Precip Type',
          field: 'precip_type_as_text',
        },
        {
          header: 'Snow Amount',
          field: 'snow_with_trace_as_text',
        },
        {
          header: 'Freezing Rain',
          field: 'ice_with_trace_as_text',
        },
        {
          header: 'Notes',
          field: 'note',
        },
      ],
    };
  };

  const handleDownloadRawData = async (location: GroupedLocation) => {
    setSelectedLocation(location);
    setDownloadingData(true);
    const res = await axios.post('/cst/storms/custom-csv', {
      ...getCustomCsvParams(location),
      format: 'csv',
    });
    download(res.data, `${getLocationName(location)}.csv`, 'text/csv');
    setSelectedLocation(undefined);
    setDownloadingData(false);
  };

  const handlePreview = async (location: GroupedLocation) => {
    setLoadingPreview(true);
    setSelectedLocation(location);
    const res = await axios.post<
      any,
      AxiosResponse<{ data: { rows: (string | number)[][] } }>
    >('/cst/storms/custom-csv', {
      ...getCustomCsvParams(location),
      format: 'json',
    });
    setRawData(res.data.data.rows);
    setPreviewingData(true);
    setLoadingPreview(false);
  };

  const handlePreviewClose = () => {
    setPreviewingData(false);
    setRawData(undefined);
    setSelectedLocation(undefined);
  };

  const formatDateString = (date: string) => {
    const dateObj = new Date(date);
    return `${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
  };

  const handleReplaceMissingData = (location: GroupedLocation) => {
    if (!location.location_id) {
      return;
    }
    const bestMatch = bestMatches.get(location.location_id);
    if (!bestMatch) {
      return;
    }

    const periods = location.periods?.map((period) => {
      const copy = { ...period };
      if (!copy.isComplete) {
        // Use the best match to set this period's source.
        const bestMatchPeriod = bestMatch.periods[period.period];
        copy.source = bestMatchPeriod.locationValue;
        copy.sourceType = bestMatchPeriod.locationType;
        if (copy.source && copy.sourceType) {
          copy.isComplete = true;
        }
      }
      return copy;
    });

    setGroupedLocations((groupableLocations) => {
      return groupableLocations.map((l) => {
        if (l.location_id === location.location_id) {
          return {
            ...l,
            periods,
          };
        } else {
          return l;
        }
      });
    });

    return {
      ...location,
      periods,
    };
  };

  const handleMarkPeriodAsComplete = (
    e: ChangeEvent<HTMLInputElement>,
    period: LocationPeriod
  ) => {
    if (!selectedLocation) {
      return;
    }
    const periods = selectedLocation.periods?.map((p) => {
      if (p.period === period.period) {
        return {
          ...p,
          isComplete: e.target.checked,
        };
      } else {
        return p;
      }
    });
    const location = {
      ...selectedLocation,
      periods,
    };
    setSelectedLocation(location);
    setGroupedLocations((locations) => {
      return locations.map((l) => {
        if (l.location_id === location.location_id) {
          return location;
        } else {
          return l;
        }
      });
    });
  };

  const handlePeriodSourceChange = (
    e: ChangeEvent<HTMLSelectElement>,
    period: LocationPeriod
  ) => {
    if (!selectedLocation || !selectedLocation.location_id) {
      return;
    }

    const bestMatch = bestMatches.get(selectedLocation.location_id as number);
    const sourceType = e.target.value as BestMatchLocationType;

    // Determine the source value
    let source = period.source;
    if (sourceType === 'climo') {
      source = bestMatch?.climo_region_id;
    } else if (sourceType === 'secondary') {
      source = bestMatch?.best_match;
    } else if (sourceType === 'primary') {
      source = bestMatch?.location_id;
    }
    const periods = selectedLocation.periods?.map((p) => {
      if (p.period === period.period) {
        return {
          ...p,
          source,
          sourceType,
          isComplete: true,
        };
      }
      return p;
    });
    const location = {
      ...selectedLocation,
      periods,
    };
    setSelectedLocation(location);
    setGroupedLocations((locations) => {
      return locations.map((l) => {
        if (l.location_id === location.location_id) {
          return location;
        } else {
          return l;
        }
      });
    });
  };

  const handleReplaceAllMissingData = async () => {
    Promise.all(groupedLocations.map(handleReplaceMissingData)).then(() => {
      setShowingFixMissingData(false);
    });
  };

  const handleGroupLocations = (location: GroupedLocation) => {
    const selectedLocations = location.groupedLocations.map(
      (l) => l.location_id
    );

    const groupableLocations = getGroupableCstLocations(location).map((l) => {
      return {
        ...l,
        selected: selectedLocations.includes(l.location_id),
      };
    });

    let groupName = '';
    if (location.city) {
      groupName = location.city;
    }
    if (location.state) {
      groupName += `, ${location.state}`;
    }

    setGroupableLocations(groupableLocations);
    setSelectedLocation({
      ...location,
      groupName,
    });
  };

  const handleGroupLocationSelect = (
    e: React.FormEvent<HTMLInputElement>,
    location: Location
  ) => {
    if (!groupableLocations) {
      return;
    }
    setGroupableLocations((groupableLocations) => {
      return groupableLocations?.map((l) => {
        if (l.location_id === location.location_id) {
          return {
            ...l,
            selected: e.currentTarget.checked,
          };
        } else {
          return l;
        }
      });
    });
  };

  const addTrustedLocation = (location: Location) => {
    setTrustedLocationIds((locationIds) => {
      const trustedLocationIds = [...locationIds];
      if (location.location_id) {
        trustedLocationIds.push(location.location_id);
      }
      return trustedLocationIds;
    });
  };

  const isTrustedLocation = (location: Location) => {
    if (!location.location_id) {
      return false;
    }
    return trustedLocationIds.includes(location.location_id);
  };

  const handleSubmit = () => {
    onSubmit(reportLocations);
  };

  const handleLocationSourcesClick = (location: GroupedLocation) => {
    setSelectedLocation(location);
    locationSourcesDisclosure.onOpen();
  };

  const handleApplyAll = (sourceType?: BestMatchLocationType) => {
    if (!selectedLocation || !selectedLocation.location_id) {
      return;
    }

    const bestMatch = bestMatches.get(selectedLocation.location_id as number);
    let source: number | string | undefined | null = null;
    if (sourceType === 'climo') {
      source = bestMatch?.climo_region_id;
    } else if (sourceType === 'secondary') {
      source = bestMatch?.best_match;
    } else if (sourceType === 'primary') {
      source = bestMatch?.location_id;
    }
    const periods = selectedLocation.periods?.map((p) => {
      let suggestedSourceType: typeof sourceType;
      let suggestedSource: typeof source;
      if (sourceType == null) {
        const bestMatchForPeriod = bestMatch?.periods[p.period];
        suggestedSourceType = bestMatchForPeriod?.locationType;
        suggestedSource = bestMatchForPeriod?.locationValue;
      }
      return {
        ...p,
        source: suggestedSource ?? source,
        sourceType: suggestedSourceType ?? sourceType,
        isComplete: true,
      };
    });
    const location = {
      ...selectedLocation,
      periods,
    };
    setSelectedLocation(location);
    setGroupedLocations((locations) => {
      return locations.map((l) => {
        if (l.location_id === location.location_id) {
          return location;
        } else {
          return l;
        }
      });
    });
  };

  const SuggestedSource = ({ period }: { period: LocationPeriod }) => {
    if (!selectedLocation?.location_id) {
      return <>-</>;
    }
    const bestMatch = bestMatches.get(selectedLocation.location_id);
    if (!bestMatch) {
      return <>-</>;
    }
    const bestMatchPeriod = bestMatch.periods[period.period];
    if (bestMatchPeriod.locationType === 'primary') {
      return <>Primary</>;
    } else if (bestMatchPeriod.locationType === 'secondary') {
      return <>Nearby CST</>;
    } else if (bestMatchPeriod.locationType === 'climo') {
      return <>Climo</>;
    } else {
      return <>-</>;
    }
  };

  const DatasetList = ({ locations }: { locations: GroupedLocation[] }) => {
    const locationsInGroup = locations
      .map((l) => l.groupedLocations.map((l) => l.location_id))
      .flat();
    const filter = (location: GroupedLocation) => {
      return !locationsInGroup.includes(location.location_id);
    };

    return (
      <List spacing={4}>
        {locations.filter(filter).map((location, index) => {
          const seasonsBySource: {
            incomplete: number[];
            incompleteFormatted: string;
            primary: number[];
            primaryFormatted: string;
            secondary: number[];
            secondaryFormatted: string;
            climo: number[];
            climoFormatted: string;
          } = {
            incomplete: [],
            incompleteFormatted: '',
            primary: [],
            primaryFormatted: '',
            secondary: [],
            secondaryFormatted: '',
            climo: [],
            climoFormatted: '',
          };

          location.periods?.forEach((period) => {
            if (!period.isComplete) {
              seasonsBySource.incomplete.push(period.period);
            } else if (period.sourceType && period.isComplete) {
              seasonsBySource[period.sourceType].push(period.period);
            }
          });

          for (const key in seasonsBySource) {
            const value = (seasonsBySource as any)[key];
            if (Array.isArray(value)) {
              const periodNames = value.map((p) => p.toString());
              let formatted: string;
              if (periodNames.length === 2) {
                formatted = periodNames.join(' & ');
              } else if (periodNames.length > 2) {
                const last = periodNames.pop();
                formatted = `${periodNames.join(', ')}, and ${last}`;
              } else {
                formatted = periodNames[0];
              }
              (seasonsBySource as any)[key + 'Formatted'] = formatted;
            }
          }

          const bestMatchInfo = bestMatches.get(location.location_id!)?.bestMatchInfo

          return (
            <ListItem key={`location${index}`}>
              <Box
                border="1px solid"
                borderColor="gray.200"
                p={2}
                borderRadius={4}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction={'row'} spacing={4}>
                  <Stack>
                    {location.groupName &&
                      location.groupedLocations.length > 0 && (
                        <Text sx={{ fontWeight: 600 }}>
                          {location.groupName}
                        </Text>
                      )}

                    <Stack alignItems={'center'} direction="row">
                      {location.needsReview && isTrustedLocation(location) && (
                        <Popover trigger="hover">
                          <PopoverTrigger>
                            <WarningTwoIcon color={'yellow.400'} />
                          </PopoverTrigger>
                          <Portal>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverHeader>
                                <Heading size={'sm'}>
                                  Meteorologist Review Recommended
                                </Heading>
                              </PopoverHeader>
                              <PopoverBody>
                                <Heading size={'sm'}></Heading>
                                <Text>
                                  This CST Location was activated during the
                                  most recent season and should be checked for
                                  completness.
                                </Text>
                              </PopoverBody>
                              <PopoverFooter>
                                <Stack>
                                  <Button
                                    leftIcon={<Search2Icon />}
                                    isFullWidth={true}
                                    onClick={() => handlePreview(location)}
                                  >
                                    Review
                                  </Button>
                                  <Button
                                    colorScheme="green"
                                    onClick={() => {
                                      addTrustedLocation(location);
                                    }}
                                  >
                                    Trust CST Location
                                  </Button>
                                </Stack>
                              </PopoverFooter>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      )}
                      <Text>
                        {location.city}, {location.state} {location.zipcode}
                      </Text>
                    </Stack>
                    {location.groupedLocations.map((l, i) => (
                      <Text key={i}>{getLocationName(l)}</Text>
                    ))}
                  </Stack>
                  <HStack>
                    {location.groupedLocations.length === 0 &&
                      seasonsBySource.incomplete.length > 0 && (
                        <Popover trigger="hover">
                          <PopoverTrigger>
                            <Tag
                              colorScheme="red"
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleLocationSourcesClick(location)
                              }
                            >
                              {0 - seasonsBySource.incomplete.length}
                            </Tag>
                          </PopoverTrigger>
                          <Portal>
                            <PopoverContent>
                              <PopoverArrow />

                              <PopoverBody>
                                <Stack spacing={4}>
                                  <Box>
                                    <Text size="md" fontWeight={600}>
                                      Missing Seasons
                                    </Text>
                                    <Text size="sm" fontWeight={400}>
                                      {seasonsBySource.incompleteFormatted}
                                    </Text>
                                  </Box>
                                  {bestMatches.get(location.location_id!)
                                    ?.location_id && (
                                    <Box>
                                      <Text size="md" fontWeight={600}>
                                        Nearby CST:{' '}
                                        <Tag colorScheme="blue">
                                          {bestMatchInfo?.city && `${bestMatchInfo?.city}, `}
                                          {bestMatchInfo?.state}{' '}
                                          {bestMatchInfo?.zipcode}
                                        </Tag>
                                      </Text>
                                    </Box>
                                  )}
                                  {bestMatches.get(location.location_id!)
                                    ?.climo_region_id && (
                                    <Box>
                                      <Text size="md" fontWeight={600}>
                                        Matched Climo:{' '}
                                        <Tag colorScheme="teal">
                                          {
                                            bestMatches.get(
                                              location.location_id!
                                            )?.climo_region_id
                                          }
                                        </Tag>
                                      </Text>
                                    </Box>
                                  )}
                                  <Divider />
                                  <Text>
                                    Would you like to use a nearby CST location
                                    and/or regional climo data to replace the
                                    missing data?
                                  </Text>
                                  <Button
                                    size="sm"
                                    colorScheme="green"
                                    onClick={() =>
                                      handleReplaceMissingData(location)
                                    }
                                  >
                                    Replace Missing Data
                                  </Button>
                                </Stack>
                              </PopoverBody>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      )}

                    {location.groupedLocations.length > 0 ? (
                      <Tag colorScheme="teal">Climo</Tag>
                    ) : (
                      <>
                        {seasonsBySource.primary.length > 0 && (
                          <Popover trigger="hover">
                            <PopoverTrigger>
                              <Tag
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                  handleLocationSourcesClick(location)
                                }
                                colorScheme="blue"
                              >
                                CST
                              </Tag>
                            </PopoverTrigger>
                            <Portal>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                  <Heading size={'sm'}>
                                    Seasons Using Primary CST
                                  </Heading>
                                  <Text>
                                    {seasonsBySource.primaryFormatted}
                                  </Text>
                                </PopoverBody>
                              </PopoverContent>
                            </Portal>
                          </Popover>
                        )}
                        {seasonsBySource.secondary.length > 0 && (
                          <Popover trigger="hover">
                            <PopoverTrigger>
                              <Tag
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                  handleLocationSourcesClick(location)
                                }
                                colorScheme="blue"
                              >
                                Nearby CST
                              </Tag>
                            </PopoverTrigger>
                            <Portal>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                  <Heading size={'sm'}>
                                    Seasons Using Nearby CST
                                  </Heading>
                                  <Text>
                                    {seasonsBySource.secondaryFormatted}
                                  </Text>
                                </PopoverBody>
                              </PopoverContent>
                            </Portal>
                          </Popover>
                        )}
                        {seasonsBySource.climo.length > 0 && (
                          <Popover trigger="hover">
                            <PopoverTrigger>
                              <Tag
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                  handleLocationSourcesClick(location)
                                }
                                colorScheme="teal"
                              >
                                Climo
                              </Tag>
                            </PopoverTrigger>
                            <Portal>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                  <Heading size={'sm'}>
                                    Seasons Using Climo
                                  </Heading>
                                  <Text>{seasonsBySource.climoFormatted}</Text>
                                </PopoverBody>
                              </PopoverContent>
                            </Portal>
                          </Popover>
                        )}
                      </>
                    )}
                  </HStack>
                </Stack>
                <ButtonGroup size={'sm'} spacing="2">
                  <IconButton
                    variant="ghost"
                    aria-label="Download Raw Data"
                    icon={<ArrowDownIcon />}
                    isLoading={downloadingData && selectedLocation === location}
                    onClick={() => handleDownloadRawData(location)}
                  ></IconButton>
                  <IconButton
                    variant={'ghost'}
                    aria-label="Preview Dataset"
                    icon={<Search2Icon />}
                    isLoading={loadingPreview && selectedLocation === location}
                    onClick={() => handlePreview(location)}
                  ></IconButton>
                  {!isMatching && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        variant={'ghost'}
                        icon={<MdMoreVert />}
                      >
                        Actions
                      </MenuButton>
                      <MenuList>
                        {getGroupableCstLocations(location).length > 0 && (
                          <MenuItem
                            onClick={() => handleGroupLocations(location)}
                          >
                            Group Locations
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            setSelectedLocation(location);
                            reconstructDatasetDisclosure.onOpen();
                          }}
                        >
                          Reconstruct Dataset
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </ButtonGroup>
              </Box>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const DataTableHeader = () => {
    if (!rawData) {
      return null;
    }
    const headers = rawData.length > 0 ? rawData[0] : [];
    return (
      <Thead>
        <Tr
          sx={{
            position: 'sticky',
            top: 0,
            background: '#fff',
          }}
        >
          {headers.map((header, index) => (
            <Th key={index}>{header}</Th>
          ))}
        </Tr>
      </Thead>
    );
  };

  const DataTableBody = () => {
    if (!rawData) {
      return null;
    }
    const isDateLike = (value: any) => {
      return (
        value instanceof Date ||
        /^\d{4}-([0][1-9]|1[0-2])-([0-2][1-9]|[1-3]0|3[01])/.test(value)
      );
    };
    const format = (value: string | number) => {
      if (typeof value === 'string' && isDateLike(value)) {
        return formatDateString(value);
      }
      return value;
    };
    return (
      <Tbody>
        {rawData
          .filter((_, i) => i !== 0)
          .map((row, i) => (
            <Tr key={i}>
              {row.map((col, j) => (
                <Td key={`${i}${j}`}>{format(col)}</Td>
              ))}
            </Tr>
          ))}
      </Tbody>
    );
  };

  return (
    <Stack spacing={4}>
      {groupedLocations.length > 0 && (
        <>
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Heading as="h5" size="sm">
              Dataset{cstLocations.length > 1 ? 's' : ''} found
            </Heading>
            {isMatching ? (
              <Badge
                colorScheme="blue"
                alignItems="center"
                sx={{ display: 'flex', alignItems: 'center', padding: 2 }}
              >
                <Stack direction="row" spacing={1}>
                  <Spinner size="sm" />
                  <Text>Running matching algorithm</Text>
                </Stack>
              </Badge>
            ) : (
              <ButtonGroup size={'sm'} spacing="2">
                <Button
                  disabled={isMatching}
                  size="sm"
                  leftIcon={<MdOutlineAutoFixHigh />}
                  onClick={() => setShowingFixMissingData(true)}
                >
                  Replace All Missing Data
                </Button>
              </ButtonGroup>
            )}
          </Flex>

          <DatasetList locations={groupedLocations} />
        </>
      )}

      <Button
        leftIcon={<ArrowForwardIcon />}
        colorScheme="blue"
        onClick={handleSubmit}
      >
        Run Climatology Report
      </Button>

      <ReconstructDatasetModal
        location={selectedLocation}
        bestMatch={
          selectedLocation?.location_id
            ? bestMatches.get(selectedLocation.location_id)
            : undefined
        }
        isOpen={reconstructDatasetDisclosure.isOpen}
        onClose={reconstructDatasetDisclosure.onClose}
        onSubmit={() => {
          console.log('done');
        }}
      />

      <Modal
        isOpen={locationSourcesDisclosure.isOpen}
        onClose={() => locationSourcesDisclosure.onClose}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{getLocationName(selectedLocation)} Sources</ModalHeader>
          <ModalCloseButton onClick={locationSourcesDisclosure.onClose} />

          <ModalBody>
            <TableContainer sx={{ maxHeight: '50vh', overflow: 'auto' }}>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr
                    sx={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 2,
                    }}
                  >
                    <Th>Season</Th>
                    <Th>Suggested</Th>
                    <Th>Source</Th>
                    <Th>Complete</Th>
                  </Tr>
                </Thead>
                <Tbody sx={{ maxHeight: '300px', overflow: 'scroll' }}>
                  {selectedLocation?.periods?.map((period, index) => (
                    <Tr key={index}>
                      <Td>{period.period}</Td>
                      <Td>
                        <SuggestedSource period={period} />
                      </Td>
                      <Td>
                        <Select
                          value={period.sourceType ?? ''}
                          onChange={(e) => handlePeriodSourceChange(e, period)}
                        >
                          <option value="primary">Primary</option>
                          <option value="secondary">Nearby CST</option>
                          <option value="climo">Climo</option>
                        </Select>
                      </Td>
                      <Td>
                        <Checkbox
                          onChange={(e) =>
                            handleMarkPeriodAsComplete(e, period)
                          }
                          isChecked={period.isComplete}
                        >
                          Mark as complete
                        </Checkbox>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Stack direction="row">
              <Menu>
                <MenuButton
                  as={Button}
                  size="sm"
                  variant="outline"
                  rightIcon={<ChevronDownIcon />}
                >
                  Apply All
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => handleApplyAll()}>
                    Use Suggested
                  </MenuItem>
                  <MenuItem onClick={() => handleApplyAll('primary')}>
                    Use Primary
                  </MenuItem>
                  <MenuItem onClick={() => handleApplyAll('secondary')}>
                    Use Nearby CST
                  </MenuItem>
                  <MenuItem onClick={() => handleApplyAll('climo')}>
                    Use Climo
                  </MenuItem>
                </MenuList>
              </Menu>
              <Button
                size="sm"
                colorScheme={'blue'}
                onClick={locationSourcesDisclosure.onClose}
              >
                Save Sources
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showingFixMissingData}
        onClose={() => setShowingFixMissingData(false)}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Replace All Missing Data</ModalHeader>
          <ModalCloseButton onClick={locationSourcesDisclosure.onClose} />
          <ModalBody>
            <Text>
              <Text>
                Would you like to use a nearby CST locations and/or regional
                climo data to replace the missing data within all the datasets?
              </Text>
            </Text>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => setShowingFixMissingData(true)}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme={'blue'}
                onClick={handleReplaceAllMissingData}
              >
                Replace Data
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={
          groupableLocations !== undefined && groupableLocations.length > 0
        }
        onClose={handleCloseGroupModal}
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Group Locations</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Alert size={'sm'}>
                <AlertDescription>
                  Locations that share a climo region with{' '}
                  <strong>{getLocationName(selectedLocation)}</strong> can be
                  grouped together as a single location. Use the options below
                  to select how you'd like to group the locations
                </AlertDescription>
              </Alert>
              <FormControl>
                <FormLabel>Location Name</FormLabel>
                <Input
                  value={selectedLocation?.groupName ?? ''}
                  onChange={(e) =>
                    setSelectedLocation((selectedLocation) =>
                      selectedLocation
                        ? {
                            ...selectedLocation,
                            groupName: e.target.value,
                          }
                        : undefined
                    )
                  }
                />
                <FormHelperText>
                  We'll use the location name you enter here rather than the
                  individual locations that have been grouped
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Select locations to group</FormLabel>
                <Stack>
                  <Checkbox
                    isChecked={groupableLocations?.every((l) => l.selected)}
                    onChange={(e) => {
                      const selected = !groupableLocations?.every(
                        (l) => l.selected
                      );
                      setGroupableLocations((groupableLocations) =>
                        groupableLocations?.map((l) => ({
                          ...l,
                          selected,
                        }))
                      );
                    }}
                  >
                    Select All
                  </Checkbox>
                  {groupableLocations?.map((location) => (
                    <Checkbox
                      key={location.location_id}
                      isChecked={location.selected ?? false}
                      onChange={(e) => handleGroupLocationSelect(e, location)}
                    >
                      {getLocationName(location)}
                    </Checkbox>
                  ))}
                </Stack>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row">
              <Button size="sm" variant="ghost" onClick={handleCloseGroupModal}>
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme={'blue'}
                onClick={handleCreateGroup}
              >
                Done
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={previewingData} onClose={handlePreviewClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dataset Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer sx={{ maxHeight: '50vh', overflow: 'auto' }}>
              <Table variant="simple" size="sm">
                <DataTableHeader />
                <DataTableBody />
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={handlePreviewClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default CstDatasetList;
