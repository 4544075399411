import React from 'react';
import { Center, Heading } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <Center sx={{ height: '100%' }}>
      <Heading as="h2" size="2xl" color="blue.400">
        Not Found
      </Heading>
    </Center>
  );
};

export default NotFound;
