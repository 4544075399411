import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  useColorModeValue,
  Center,
  Flex,
} from '@chakra-ui/react';
import { CenterLayout } from '../layouts/CenterLayout';
import ReportContext from '../context/reportContext';
import {
  Report,
  Location,
  ReportLocation,
  ReportPeriodType,
  CstBestMatch,
} from '../types';
import ReportForm from '../components/ReportForm';
import CstDatasetList from '../components/CstDatasetList';
import { ArrowBackIcon } from '@chakra-ui/icons';
import ReportDownloadList from '../components/ReportDownloadList';
import CstLocations from '../components/CstLocations';
import CostCalculator from '../components/CostCalculator';
import ClientSelect from '../components/ClientSelect';

export const EMPTY_LOCATION: Location = {
  city: '',
  state: '',
  zipcode: '',
};

const initalPeriodType: ReportPeriodType = 'Winter Season';
const initialEndYear = (peiodType: ReportPeriodType) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const priorYear = currentYear - 1;
  if (peiodType === 'Calendar Year') {
    return priorYear;
  } else if (peiodType === 'Winter Season') {
    return now.getTime() < new Date().setMonth(3, 15) ? priorYear : currentYear;
  } else {
    return currentYear;
  }
};

const INITIAL_REPORT: Report = {
  client: {
    firstName: '',
    lastName: '',
    companyName: '',
  },
  precip: 'snowice',
  type: 'snowtistics',
  timeframe: '15-Year',
  includeStandard: false,
  locations: [{ ...EMPTY_LOCATION }],
  periodType: initalPeriodType,
  endYear: initialEndYear(initalPeriodType),
  startYear: 2020 - 15,
};

const Snowtistics: FunctionComponent = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [cstLocations, setCstLocations] = useState<Location[]>([]);
  const [reportLocations, setReportLocations] = useState<ReportLocation[]>([]);
  const [report, setReport] = useState<Report>({ ...INITIAL_REPORT });
  const [bestMatches, setBestMatches] = useState<Map<number, CstBestMatch>>(
    new Map<number, CstBestMatch>()
  );
  const handleReportFormSubmit = (cstLocations: Location[]) => {
    setCstLocations(cstLocations);
    setStepIndex((index) => index + 1);
  };

  const handleLocationsSubmit = (cstLocations: Location[]) => {
    setCstLocations(cstLocations);
    setStepIndex((index) => index + 1);
  };

  const handleDatasetListSubmit = (locations: ReportLocation[]) => {
    setStepIndex((index) => index + 1);
    setReportLocations(locations);
  };

  const handleReportLocationChange = (locations: ReportLocation[]) => {
    setReportLocations(locations);
  };

  const handleClientSubmit = () => {
    setStepIndex((index) => index + 1);
  };

  const handleBackClick = () => {
    setStepIndex((index) => index - 1);
  };

  const handleReset = () => {
    setReport({ ...INITIAL_REPORT });
    setReportLocations([]);
    setCstLocations([]);
    setStepIndex(0);
  };

  const wizardSteps = [
    {
      title: 'Client Info',
      content: <ClientSelect onSubmit={handleClientSubmit} />,
    },
    {
      title: 'Report Settings',
      content: <ReportForm onSubmit={handleReportFormSubmit} />,
    },
    {
      title: 'Locations',
      content: <CstLocations onSubmit={handleLocationsSubmit} />,
    },
    {
      title: 'Dataset Locations',
      content: (
        <CstDatasetList
          onSubmit={handleDatasetListSubmit}
          onReportLocationsChange={handleReportLocationChange}
        />
      ),
    },
    {
      title: 'Download Reports',
      content: <ReportDownloadList locations={reportLocations} />,
    },
  ];

  const canGoBack = useMemo(() => {
    return stepIndex > 0;
  }, [stepIndex]);

  const isLastStep = useMemo(() => {
    return stepIndex === wizardSteps.length - 1;
  }, [stepIndex, wizardSteps.length]);

  return (
    <ReportContext.Provider
      value={{
        report,
        setReport,
        cstLocations,
        reportLocations,
        bestMatches,
        setBestMatches,
      }}
    >
      <CenterLayout>
        <Stack spacing={2} sx={{ minWidth: '60%' }}>
          {canGoBack && (
            <Flex justifyContent={'space-between'}>
              <Box justifyContent="space-between" alignContent="center">
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  size="sm"
                  onClick={handleBackClick}
                >
                  {wizardSteps[stepIndex - 1]?.title ?? 'Back'}
                </Button>
              </Box>
              <CostCalculator />
            </Flex>
          )}

          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="md"
            p={8}
          >
            {wizardSteps[stepIndex].content}
          </Box>

          {isLastStep && (
            <Center>
              <Button
                onClick={handleReset}
                marginTop={8}
                size="sm"
                variant={'outline'}
                colorScheme={'blue'}
              >
                Start Another Request
              </Button>
            </Center>
          )}
        </Stack>
      </CenterLayout>
    </ReportContext.Provider>
  );
};
export default Snowtistics;
