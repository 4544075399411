import React from 'react';
import { Center, CircularProgress } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Center sx={{ height: '100%' }}>
      <CircularProgress isIndeterminate color="blue.300" />
    </Center>
  );
};

export default Loading;
