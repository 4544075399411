import { FC, useCallback, useMemo } from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { useAuth } from '../context/authContext';
import { MdApps } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

const TopNav: FC = () => {
  const location = useLocation();

  const currentApp = useMemo(() => {
    const pathPrefix = location.pathname.split('/')[1];
    return pathPrefix === '' ? null : pathPrefix;
  }, [location]);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const Logo = useCallback(() => {
    if (currentApp === 'snowtistics') {
      return <Image src="/logo.png" alt="Snowtistics" height="36px" />;
    }
    return (
      <Image
        src="/weatherworks.png"
        alt="Snowtistics"
        height="28px"
        sx={{ marginLeft: '50px' }}
      />
    );
  }, [currentApp]);

  const handleLogout = () => {
    logout();
  };

  return (
    <Flex
      position="sticky"
      top="0"
      w="100%"
      zIndex={99}
      bg={useColorModeValue('white', 'gray.700')}
      minHeight="48px"
      alignItems="center"
      justifyContent={'space-between'}
      marginBottom={4}
      boxShadow="md"
      paddingEnd={4}
    >
      <Logo />
      <ButtonGroup variant={'ghost'} colorScheme="blue">
        <IconButton
          aria-label="Apps"
          icon={<MdApps />}
          onClick={() => {
            navigate('/');
          }}
        ></IconButton>
        <Button onClick={handleLogout}>Logout</Button>
      </ButtonGroup>
    </Flex>
  );
};
export default TopNav;
