import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { useAuth } from './context/authContext';
import Home from './pages/Home';
import Loading from './pages/Loading';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Snowtistics from './pages/Snowtistics';

const App: FC<unknown> = () => {
  const auth = useAuth();
  if (auth.loading) {
    return <Loading />;
  }

  const RootPath = () => {
    if (!auth.token) {
      return <Navigate to="/login" />;
    } else {
      return <Home />;
    }
  };

  const FourOhFour = () => {
    if (!auth.token) {
      return <Navigate to="/login" />;
    } else {
      return <NotFound />;
    }
  };

  const isLoggedIn = auth.token !== null;

  return (
    <div className="App" style={{ height: '100%' }}>
      <Routes>
        <Route key="/" path="/" element={<RootPath />} />
        <Route key="/snowtistics" path="/snowtistics" element={<Snowtistics />} />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/" /> : <Login />}
        />
        <Route key="*" path="*" element={<FourOhFour />} />;
      </Routes>
    </div>
  );
};

export default App;
