import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Alert,
  AlertDescription,
  Spinner,
  Center,
  useToast,
} from '@chakra-ui/react';

import { TargetBox } from './TargetBox';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Location, CstBestMatch } from '../types';
import ExcelJS from 'exceljs';
import { useState } from 'react';
import dayjs from 'dayjs';
import { AttachmentIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/authContext';

interface ReconstructDatasetModalProps {
  location?: Location;
  bestMatch?: CstBestMatch;
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const ReconstructDatasetModal = ({
  bestMatch,
  isOpen,
  onClose,
}: ReconstructDatasetModalProps) => {
  const toast = useToast();
  const { axios } = useAuth();
  const [isProcessing, setIsProccessing] = useState(false);
  const [startYmd, setStartYmd] = useState('');
  const [endYmd, setEndYmd] = useState('');
  const [rows, setRows] = useState<
    {
      date: any;
      snowAmount?: number;
      snowTrace?: number;
      iceAmount: number;
      iceTrace: number;
    }[]
  >([]);

  const handleDrop = async (item: { files?: File[] }) => {
    setIsProccessing(true);
    if (item.files === undefined || item.files.length === 0) {
      return;
    }

    const file = item.files[0];
    const data = await file.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    try {
      await workbook.xlsx.load(data);
    } catch (error) {
      setIsProccessing(false);
      return;
    }
    const sheet = workbook.worksheets[0];
    for (let i = 2; i < sheet.rowCount + 1; i++) {
      const date = dayjs(sheet.getCell(i, 1).value?.toString());
      const snowAmount = parseFloat(
        sheet.getCell(i, 2).value?.toString() ?? '0'
      );
      const snowTrace = parseFloat(
        sheet.getCell(i, 3).value?.toString() ?? '0'
      );
      const iceAmount = parseFloat(
        sheet.getCell(i, 4).value?.toString() ?? '0'
      );
      const iceTrace = parseFloat(sheet.getCell(i, 5).value?.toString() ?? '0');
      rows.push({
        date,
        snowAmount: isNaN(snowAmount) ? 0 : snowAmount,
        snowTrace: isNaN(snowTrace) ? 0 : snowTrace,
        iceAmount: isNaN(iceAmount) ? 0 : iceAmount,
        iceTrace: isNaN(iceTrace) ? 0 : iceTrace,
      });
    }
    setRows(rows);
    setIsProccessing(false);
  };

  const canSubmit = () => {
    return rows.length > 0 && startYmd.length > 0 && endYmd.length > 0;
  };
  const handleSubmit = () => {
    axios
      .post('/cst/storms/climo-backfill', {
        regionId: bestMatch?.climo_region_id,
        start: startYmd,
        end: endYmd,
        events: rows.map((row) => ({
          date: row.date.format('YYYY-MM-DD'),
          snow_amount: row.snowAmount,
          snow_trace: row.snowTrace,
          ice_amount: row.iceAmount,
          ice_trace: row.iceTrace,
        })),
      })
      .then(() => {
        onClose();
        toast({
          title: 'Success',
          description: 'Climo data was successfully backfilled',
          status: 'success',
        });
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
        });
      });
  };

  const handleDownload = () => {
    window.location.pathname = '/excel-templates/ClimoDatasetTemplate.xlsx';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Reconstruct Dataset for Climo {bestMatch?.climo_region_id}{' '}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Alert>
              <Stack>
                <AlertDescription>
                  Download the template below to create a dataset for
                  {bestMatch?.climo_region_id} that can then be imported.
                </AlertDescription>
                <Button
                  onClick={handleDownload}
                  variant="link"
                  size="sm"
                  leftIcon={<AttachmentIcon />}
                >
                  Download Template
                </Button>
              </Stack>
            </Alert>
            <FormControl isRequired>
              <FormLabel>Start Date</FormLabel>
              <Input
                value={startYmd}
                type="date"
                onChange={(e) => {
                  setStartYmd(e.target.value);
                }}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>End Date</FormLabel>
              <Input
                type="date"
                value={endYmd}
                onChange={(e) => setEndYmd(e.target.value)}
              />
            </FormControl>
            {isProcessing && (
              <Center>
                <Spinner />
              </Center>
            )}
            {!rows?.length && !isProcessing && (
              <DndProvider backend={HTML5Backend}>
                <TargetBox onDrop={handleDrop}></TargetBox>
              </DndProvider>
            )}
            {rows?.length > 0 && !isProcessing && (
              <>
                <TableContainer sx={{ maxHeight: '25vh', overflow: 'auto' }}>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr
                        sx={{
                          position: 'sticky',
                          top: 0,
                          background: '#fff',
                          zIndex: 2,
                        }}
                      >
                        <Th>Date</Th>
                        <Th isNumeric>Snow Amount</Th>
                        <Th isNumeric>Snow Trace</Th>
                        <Th isNumeric>Ice Amount</Th>
                        <Th isNumeric>Ice Trace</Th>
                      </Tr>
                    </Thead>
                    <Tbody sx={{ maxHeight: '300px', overflow: 'scroll' }}>
                      {rows.map((row, index) => (
                        <Tr key={index}>
                          <Td isNumeric>{row.date?.format('M/D/YYYY')}</Td>
                          <Td isNumeric>{row.snowAmount}</Td>
                          <Td isNumeric>{row.snowTrace}</Td>
                          <Td isNumeric>{row.iceAmount}</Td>
                          <Td isNumeric>{row.iceTrace}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Button onClick={() => setRows([])}>Clear Dataset</Button>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              disabled={rows?.length > 0}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isDisabled={!canSubmit()}
            >
              Reconstruct Dataset
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ReconstructDatasetModal;
