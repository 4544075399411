import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import {
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  List,
  ListItem,
  Portal,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useAuth } from '../context/authContext';
import { Company } from '../types';

export interface CityAutoCompleteProps {
  onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
  onSelect?: (company: Company) => void;
  state?: string;
  value?: string;
}

const ClientAutoComplete = ({
  state,
  value,
  onChange,
  onSelect,
}: CityAutoCompleteProps) => {
  const { axios } = useAuth();
  const [items, setItems] = useState<Company[]>([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = (e: any, item: Company) => {
    if (onSelect) {
      onSelect(item);
    }
    setHasFocus(false);
    inputRef.current?.blur();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.target.value);
    }
  };

  useEffect(() => {
    if (value && value.length > 2) {
      axios
        .post('/companies/search', {
          searchTerm: value,
        })
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          const items = res.data.companies ?? [];
          setItems(items);
          setSelectedIndex(-1);
        });
    } else {
      setItems([]);
    }
  }, [value, hasFocus, axios, state]);

  const handleKeyDown = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      if (selectedIndex >= 0) {
        const item = items[selectedIndex];
        handleSelect(e, item);
      }
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let step = 0;
      if (e.key === 'ArrowDown') {
        step = 1;
      } else if (e.key === 'ArrowUp') {
        step = -1;
      }
      setSelectedIndex((selectedIndex) => {
        const newIndex = selectedIndex + step;
        return newIndex < 0 ? items.length - 1 : newIndex % items.length;
      });

      if (step === 0) {
        return;
      }
    } else if (e.key === 'Escape') {
      setSelectedIndex(-1);
    }
  };

  return (
    <Popover
      returnFocusOnClose={false}
      autoFocus={false}
      isOpen={hasFocus && items.length > 0}
    >
      <PopoverTrigger>
        <Input
          ref={inputRef}
          placeholder="Company Name"
          autoComplete="off"
          value={value ?? ''}
          onChange={handleChange}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          onKeyDown={handleKeyDown}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <List onKeyDown={handleKeyDown} ref={listRef}>
              {items.map((item, index) => (
                <ListItem
                  key={item.company_id}
                  onClick={(e) => handleSelect(e, item)}
                  onMouseOver={() => setSelectedIndex(index)}
                  onMouseLeave={() => setSelectedIndex(-1)}
                  onMouseDown={(e) => e.preventDefault()}
                  sx={{
                    padding: '0.5rem',
                    cursor: 'pointer',
                    backgroundColor:
                      selectedIndex === index ? 'gray.100' : 'transparent',
                  }}
                >
                  <Flex justifyContent="space-between">
                    <Text>{item.name}</Text>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ClientAutoComplete;
