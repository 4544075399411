import React from 'react';
import { ReportTimeFrame, ReportType } from '../types';
import ReportContext from '../context/reportContext';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
  PopoverTrigger,
  PopoverBody,
  PopoverArrow,
  PopoverContent,
  Popover,
  Portal,
  PopoverHeader,
  Heading,
  Button,
  Text,
} from '@chakra-ui/react';

type TimeframeCostMap = {
  [key in ReportTimeFrame]: number;
};
type BaseCostMap = {
  [reportType in ReportType]: TimeframeCostMap;
};

interface LineItem {
  description: string;
  quantity: number;
  unitCost: number;
}

const freeBaseCosts: TimeframeCostMap = {
  '5-Year': 0,
  '10-Year': 0,
  '15-Year': 0,
  '20-Year': 0,
  '25-Year': 0,
  '30-Year': 0,
};

const baseCostMap: BaseCostMap = {
  average: {
    '5-Year': 10,
    '10-Year': 15,
    '15-Year': 25,
    '20-Year': 27.5,
    '25-Year': 30,
    '30-Year': 32.5,
  },
  seasonal: {
    '5-Year': 20,
    '10-Year': 45,
    '15-Year': 55,
    '20-Year': 60,
    '25-Year': 65,
    '30-Year': 70,
  },
  'month-season': {
    '5-Year': 20,
    '10-Year': 45,
    '15-Year': 55,
    '20-Year': 60,
    '25-Year': 65,
    '30-Year': 70,
  },
  'snowtistics-lite': {
    '5-Year': 50,
    '10-Year': 75,
    '15-Year': 95,
    '20-Year': 115,
    '25-Year': 120,
    '30-Year': 125,
  },
  snowtistics: {
    '5-Year': 75,
    '10-Year': 115,
    '15-Year': 150,
    '20-Year': 195,
    '25-Year': 245,
    '30-Year': 295,
  },
  '30-year-standard': freeBaseCosts,
  'calendar-year-totals': freeBaseCosts,
  'calendar-year-monthly-totals-only': freeBaseCosts,
  raw: freeBaseCosts,
  custom: freeBaseCosts,
};

const lineItemDescription: { [key in ReportType]: string } = {
  '30-year-standard': '30-Year Standard',
  'calendar-year-totals': 'Calendar Year Totals',
  'calendar-year-monthly-totals-only': 'Calendar Year Monthly Totals',
  average: 'Average',
  seasonal: 'Seasonal',
  'month-season': 'Month-Season',
  'snowtistics-lite': 'Snowtistics Lite',
  snowtistics: 'Snowtistics',
  raw: 'Raw',
  custom: 'Custom',
};

const CostCalculator = () => {
  const reportContext = React.useContext(ReportContext);
  if (!reportContext) {
    throw new Error('ReportForm must be used within a ReportContext');
  }

  const { report, cstLocations, reportLocations } = reportContext;

  // report.location is the raw location data from the form, it's not
  // validated against CST locations, but it used to calculate an estimate.
  let quantity = report.locations.length;
  if (reportLocations.length > 0) {
    // If there are report locations, then we'll use that as the quantity since it's
    // the final grouped CST/climo locations, the report will actually use.
    quantity = reportLocations.length;
  } else if (cstLocations.length) {
    // If there are CST locations, then we'll use that as the quantity since it's
    // more accurate than the raw location entries
    quantity = cstLocations.length;
  }

  let description: string = lineItemDescription[report.type];
  if (report.type !== '30-year-standard') {
    description = `${report.timeframe} ${description}`;
  }

  const lineItems = [
    {
      description,
      quantity,
      unitCost: baseCostMap[report.type][report.timeframe],
    },
  ];
  if (report.precip === 'snowice') {
    lineItems.push({
      description: 'Snow & Ice',
      quantity,
      unitCost: 15,
    });
  }

  // Discounts
  let discount:
    | {
        description: string;
        percentage: number;
      }
    | undefined;

  const products = report.client.products ?? [];
  if (
    products.some(
      (p) => p.name === 'Storm Alert' || p.name === 'Forecast Management System'
    )
  ) {
    discount = {
      description: 'Alert/FMS',
      percentage: 0.25,
    };
  } else if (
    products.length === 1 &&
    products[0].name === ' Certified Snowfall Totals'
  ) {
    discount = {
      description: 'CST Only',
      percentage: 0.1,
    };
  }

  const subtotal = lineItems.reduce(
    (acc, item) => acc + item.quantity * item.unitCost,
    0
  );
  const multiplier = discount ? 1 - discount.percentage : 1;
  const total = subtotal * multiplier;

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const LineItemDescription = ({
    lineItem,
    maxLength = 16,
  }: {
    lineItem: LineItem;
    maxLength: number;
  }) => {
    let description = lineItem.description;
    if (lineItem.description.length > maxLength) {
      description = lineItem.description.slice(0, maxLength - 3) + '...';
    }
    if (description !== lineItem.description) {
      return <Tooltip label={lineItem.description}>{description}</Tooltip>;
    } else return <>{description}</>;
  };

  if (quantity === 0) {
    return null;
  }

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Button size="sm">
          {quantity} {quantity === 1 ? 'Report' : 'Reports'} -{' '}
          {formatter.format(total)}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent sx={{ minWidth: '500px' }}>
          <PopoverArrow />
          <PopoverHeader>
            <Heading size={'sm'}>Estimated Cost</Heading>
          </PopoverHeader>
          <PopoverBody>
            <TableContainer>
              <Table variant="simple" size={'sm'}>
                <Thead>
                  <Tr>
                    <Th>Desc</Th>
                    <Th>Qty</Th>
                    <Th isNumeric>Base</Th>
                    <Th isNumeric>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lineItems.map((lineItem, index) => (
                    <Tr key={index}>
                      <Td>
                        <LineItemDescription
                          lineItem={lineItem}
                          maxLength={16}
                        />
                      </Td>
                      <Td isNumeric>{lineItem.quantity}</Td>
                      <Td isNumeric>{formatter.format(lineItem.unitCost)}</Td>
                      <Td isNumeric>
                        {formatter.format(
                          lineItem.unitCost * lineItem.quantity
                        )}
                      </Td>
                    </Tr>
                  ))}
                  {discount && (
                    <>
                      <Tr>
                        <Td colSpan={3} isNumeric>
                          Subtotal:
                        </Td>
                        <Td isNumeric>{formatter.format(subtotal)}</Td>
                      </Tr>
                      <Tr>
                        <Td colSpan={3} isNumeric>
                          Discount
                          <Text sx={{ color: 'gray.500' }}>
                            {discount.description} ({discount.percentage * 100}
                            %)
                          </Text>
                        </Td>
                        <Td isNumeric>
                          ({formatter.format(subtotal * discount.percentage)})
                        </Td>
                      </Tr>
                    </>
                  )}
                  <Tr>
                    <Td colSpan={3} isNumeric>
                      Total:
                    </Td>
                    <Td isNumeric>{formatter.format(total)}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default CostCalculator;
