import React, { SetStateAction } from 'react';
import { Report, Location, ReportLocation, CstBestMatch } from '../types';
interface ReportContext {
  report: Report;
  setReport: React.Dispatch<React.SetStateAction<Report>>;
  cstLocations: Location[];
  setBestMatches: React.Dispatch<SetStateAction<Map<number, CstBestMatch>>>;
  bestMatches: Map<number, CstBestMatch>;
  reportLocations: ReportLocation[];
}
const Context = React.createContext<ReportContext | undefined>(undefined);
export default Context;
