import React, { FunctionComponent } from 'react';
import { Center } from '@chakra-ui/react';
import TopNav from '../components/TopNav';

export const CenterLayout: FunctionComponent = ({ children }) => {
  return (
    <>
      <TopNav />
      <Center as="main" p={4}>
        {children}
      </Center>
    </>
  );
};
