import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  ThemingProps,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import { FC, useCallback, useState } from 'react';
import { Report } from '../types';
import SnowIceBoundsTable, {
  ChangedBoundsParams,
  UpperBounds,
} from './SnowIceBoundsTable';

const SNOW_STEP_SIZE = 0.1;
const ICE_STEP_SIZE = 0.01;

const DEFAULT_SNOW_RANGES = [0.1, 1, 2, 4, 6, 8, 10, 12, 16, 20];
const DEFAULT_ICE_RANGES = [0.1, 0.2, 0.3, 0.45, 0.6, 0.8, 1];

const DEFAULT_SNOW_EVENT_RANGES = [0.1, 2, 6, 12];
const DEFAULT_SNOW_EVENT_LABELS = [
  'Trace',
  'Salting',
  'Plowing',
  'Significant',
  'Major',
];

export interface SnowIceBoundsModalResult {
  snowRanges: number[];
  iceRanges: number[];
  snowEventRanges: number[];
  snowEventLabels: string[];
}

interface SnowIceBoundsModalProps {
  report: Report;
  isOpen: boolean;
  onSubmit: (result: SnowIceBoundsModalResult) => void;
  onClose: () => void;
}

const SnowIceBoundsModal: FC<SnowIceBoundsModalProps> = ({
  report,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const makeUpperBounds = (ranges: number[], stepSize: number) => {
    return ranges.map((lowerBound, index) =>
      index === 0 ? lowerBound : lowerBound - stepSize
    );
  };

  const [isEditing, setIsEditing] = useState(false);

  const [snowRanges, setSnowRanges] = useState([...DEFAULT_SNOW_RANGES]);
  const [snowUpperBounds, setSnowUpperBounds] = useState<UpperBounds>(
    makeUpperBounds(DEFAULT_SNOW_RANGES, SNOW_STEP_SIZE)
  );

  const [isSnowRangeValid, setIsSnowRangeValid] = useState(true);

  const [iceRanges, setIceRanges] = useState([...DEFAULT_ICE_RANGES]);
  const [iceUpperBounds, setIceUpperBounds] = useState<UpperBounds>(
    makeUpperBounds(DEFAULT_ICE_RANGES, ICE_STEP_SIZE)
  );
  const [isIceRangeValid, setIsIceRangeValid] = useState(true);

  const [snowEventRanges, setSnowEventRanges] = useState([
    ...DEFAULT_SNOW_EVENT_RANGES,
  ]);
  const [snowEventUpperBounds, setSnowEventUpperBounds] = useState<UpperBounds>(
    makeUpperBounds(DEFAULT_SNOW_EVENT_RANGES, SNOW_STEP_SIZE)
  );
  const [snowEventLabels, setSnowEventLabels] = useState([
    ...DEFAULT_SNOW_EVENT_LABELS,
  ]);
  const [isSnowEventRangeValid, setIsSnowEventRangeValid] = useState(true);

  const handleSnowBoundsChange = ({
    lowerBounds,
    upperBounds,
    isValid,
  }: ChangedBoundsParams) => {
    if (isValid && lowerBounds) {
      setSnowRanges(lowerBounds);
    }
    setSnowUpperBounds(upperBounds);
    setIsSnowRangeValid(isValid);
  };

  const handleSnowEventBoundsChange = ({
    lowerBounds,
    upperBounds,
    isValid,
    labels,
  }: ChangedBoundsParams) => {
    if (isValid && lowerBounds) {
      setSnowEventRanges(lowerBounds);
      if (labels) {
        setSnowEventLabels(labels);
      }
    }
    setSnowEventUpperBounds(upperBounds);
    setIsSnowEventRangeValid(isValid);
  };

  const handleIceBoundsChange = ({
    lowerBounds,
    upperBounds,
    isValid,
  }: ChangedBoundsParams) => {
    if (isValid && lowerBounds) {
      setIceRanges(lowerBounds);
    }
    setIsIceRangeValid(isValid);
    setIceUpperBounds(upperBounds);
  };

  const handleResetClick = () => {
    // Snow
    setSnowRanges(DEFAULT_SNOW_RANGES);
    setSnowUpperBounds(makeUpperBounds(DEFAULT_SNOW_RANGES, SNOW_STEP_SIZE));
    setIsSnowRangeValid(true);

    // Ice
    setIceRanges(DEFAULT_ICE_RANGES);
    setIceUpperBounds(makeUpperBounds(DEFAULT_ICE_RANGES, ICE_STEP_SIZE));
    setIsIceRangeValid(true);

    // Snow Events
    setSnowEventLabels(DEFAULT_SNOW_EVENT_LABELS);
    setSnowEventRanges(DEFAULT_SNOW_EVENT_RANGES);
    setSnowEventUpperBounds(
      makeUpperBounds(DEFAULT_SNOW_EVENT_RANGES, SNOW_STEP_SIZE)
    );
    setIsSnowEventRangeValid(true);

    setIsEditing(false);
  };

  const handleSubmit = useCallback(() => {
    onSubmit({
      snowRanges,
      iceRanges,
      snowEventRanges,
      snowEventLabels,
    });
  }, [snowRanges, iceRanges, snowEventLabels, snowEventRanges, onSubmit]);

  let size: ThemingProps<'Modal'>['size'];

  const stackCss: CSSObject = {};

  if (report.precip === 'snowice') {
    // The modal needs to be a bit bigger to accomodte the snow & ice table.
    size = isEditing ? '4xl' : 'xl';
  } else {
    size = isEditing ? 'lg' : 'md';
    stackCss.display = 'block';
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {report.precip === 'ice' && 'Ice Ranges'}
          {report.precip === 'snow' && 'Snow Ranges'}
          {report.precip === 'snowice' && 'Snow & Ice Ranges'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>Snow</Tab>
              <Tab>Ice</Tab>
              <Tab>Snow Events</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <SnowIceBoundsTable
                  isEditing={isEditing}
                  mode="snow"
                  upperBounds={snowUpperBounds}
                  onChange={handleSnowBoundsChange}
                />
              </TabPanel>
              <TabPanel>
                <SnowIceBoundsTable
                  isEditing={isEditing}
                  mode="ice"
                  upperBounds={iceUpperBounds}
                  onChange={handleIceBoundsChange}
                />
              </TabPanel>
              <TabPanel>
                <SnowIceBoundsTable
                  isEditing={isEditing}
                  mode="snow"
                  upperBounds={snowEventUpperBounds}
                  labels={snowEventLabels}
                  onChange={handleSnowEventBoundsChange}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/*}
          <Stack direction="row" spacing={isEditing ? 20 : 10} sx={stackCss}>
            {report.precip.includes('snow') && (
              <SnowIceBoundsTable
                showHeader={showHeader}
                isEditing={isEditing}
                mode="snow"
                lowerBounds={snowRanges}
                onChange={handleSnowBoundsChange}
              />
            )}
            {report.precip.includes('ice') && (
              <SnowIceBoundsTable
                showHeader={showHeader}
                isEditing={isEditing}
                mode="ice"
                lowerBounds={iceRanges}
                onChange={handleIceBoundsChange}
              />
            )}
          </Stack>
            */}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            {isEditing ? (
              <>
                <Button onClick={handleResetClick}>Reset To Defaults</Button>
                <Button
                  colorScheme="blue"
                  disabled={
                    !isSnowRangeValid ||
                    !isIceRangeValid ||
                    !isSnowEventRangeValid
                  }
                  onClick={handleSubmit}
                >
                  Use Custom Ranges
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => setIsEditing(true)}>Customize</Button>
                <Button colorScheme="blue" onClick={handleSubmit}>
                  Use Defaults
                </Button>
              </>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default SnowIceBoundsModal;
